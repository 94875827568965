import {FolderVerificationService} from '../../../../../../../core/services/folder-verification.service';
import {FoldersService} from '../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {DocumentsMessagesPopupComponent} from '../documents-messages-popup/documents-messages-popup.component';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentModel} from '../../../../../../../core/ek-e-commerce/ek-models/document.model';
import {GenericObject} from '../../../../../../../core/ek-e-commerce/ek-models/generic-object.model';
import {ChecklistService} from '../../../../../../../core/ek-e-commerce/ek-services/checklist.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DocumentService} from '../../../../../../../core/services/document.service';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';
import {AddCommentComponent} from '../../folder-steps/folder-step-eight/add-comment/add-comment.component';
import {MatDialog} from '@angular/material/dialog';


interface openDate {
    idDoc: number;
    date: string;
}

@Component({
    selector: 'kt-second-step-check-list',
    templateUrl: './second-step-check-list.component.html',
    styleUrls: ['./second-step-check-list.component.scss']
})

export class SecondStepCheckListComponent implements OnInit {

    idFolder = 0;
    idOrder = 0;
    idDocument: number;
    checkList: GenericObject<any>;
    genericChecklist: GenericObject<any> = {};
    constCheck: any;
    mainDocument: DocumentModel;

    private checkSbj$ = new BehaviorSubject<GenericObject<any>>(null)
    public checkObs$ = this.checkSbj$.asObservable()

    private docStateSbj$ = new BehaviorSubject<string>(null)
    public docStateObs$ = this.docStateSbj$.asObservable()


    isValid = false;
    checklistEvents: { key: string, checkEventName: string }[] = [];
    validatedCheckListsNbr: number;
    title: string;
    listOfDocsIds: number[] = [];

    idIndex = 0;
    currentRole = '';
    openDateObj: openDate[] = [];
    currentDocOpeningDate = '';

    private folderSbj$ = new BehaviorSubject<string>('');
    public FolderObs$ = this.folderSbj$.asObservable();

    public folderRequestNumber$: Observable<string>;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private checklistService: ChecklistService,
                private documentService: DocumentService,
                private layoutUtilsService: LayoutUtilsService,
                public folderService: FoldersService,
                public folderVerificationService: FolderVerificationService,
                public dialog: MatDialog) {

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.route.paramMap.subscribe(params => {
            this.idOrder = Number(params.get('idOrder'));
            this.idFolder = Number(params.get('idFolder'));
            this.idDocument = Number(params.get('idDoc'));
            this.title = this.route.snapshot.data['title'];

        });

        this.getFolderById();

        this.route.data.subscribe((res) => {
            this.mainDocument = res.payload.body;
            this.documentService.currentDocSbj$.next(res.payload.body);
            this.docStateSbj$.next(res.payload.body.documentsState);
            this.getCheckList();
        });

        this.folderService.isChecked(this.idFolder).subscribe((res) => {
            if (res) {
                if (res.body == false) {
                    //get client info from ocr
                    this.folderService.isCheckSbj$.next(true);
                } else {
                    this.folderService.isCheckSbj$.next(false);
                }
            }
        });


        this.listOfDocsIds = JSON.parse(localStorage.getItem('listOfDocsIds'));

        //current doc opening date ...
        this.openDateObj = JSON.parse(localStorage.getItem('docsOpenedAt'));
        this.currentDocOpeningDate = this.openDateObj.find(obj => obj.idDoc === this.idDocument).date;

    }

    openMessagesPopup() {
        const routeInfo = {
            orderId: this.idOrder,
            folderId: this.idFolder
        };
        this.dialog.open(DocumentsMessagesPopupComponent, {
            height: "fit-content",
            width: "700px",
            maxHeight: "550px",
            panelClass: 'ocr-messages-popup',
            data: routeInfo
        });
    }

    getCheckList() {

        //intialise
        this.checkSbj$.next(null);

        //get checklist if only file is different from PHOTO_IDENTITY
        if (this.mainDocument.documentsGender !== 'PHOTO_IDENTITY' && this.mainDocument.documentsGender !== 'FEES_STATEMENT') {

            this.checklistService.getCheckListByFolderId(this.idFolder, this.mainDocument.documentsGender, this.mainDocument.documentsTtitle).subscribe(
                (response) => {
                    this.genericChecklist = response;
                    this.constCheck = response;
                    this.checkSbj$.next(response);
                    this.isValid = this.checkIsValid(response);
                },
                (error: any) => {
                    this.checkSbj$.next(null);
                }
            );
        } else {
            this.checkSbj$.next(null);
        }


    }

    validateCheckList(checkItem: GenericObject<any>) {
        this.checkList = checkItem;
        this.isValid = this.checkIsValid(this.checkList);
    }

    checkIsValid(checklist: GenericObject<any>): boolean {
        const lengthOfObject = Object.keys(checklist).length;
        this.validatedCheckListsNbr = 0;
        for (let key in checklist) {
            if (checklist[key] === 'MANUAL_VALIDATED'
                || checklist[key] === 'OCR_VALIDATED_MANUAL_VALIDATED'
                || checklist[key] === 'OCR_NOT_VALIDATED_MANUAL_VALIDATED'
                || checklist[key] === 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED'
                || checklist[key] === 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED'
                || checklist[key] === 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED') {
                this.validatedCheckListsNbr++;
            }
        }
        return this.validatedCheckListsNbr === lengthOfObject;
    }


    back() {
        this.router.navigateByUrl('ek-ecommerce/folder/edit/' + this.idOrder + '/' + this.idFolder + '?step=2');
    }

    nextFile() {

        const currentIndex = this.listOfDocsIds.indexOf(this.idDocument);

        let nextFileId = this.listOfDocsIds[currentIndex + 1];

        this.idIndex++;

        if (nextFileId === undefined) {
            nextFileId = this.listOfDocsIds[0];
        }

        const folderState = this.folderSbj$.value;

        if (this.idIndex !== this.listOfDocsIds.length) {
            this.router.navigate(['ek-ecommerce/folder/edit/'
            + this.idOrder
            + '/'
            + this.idFolder
            + '/checklist/'
            + nextFileId], {
                queryParams: {
                    folderState: folderState
                }
            });
        } else {
            this.back();
        }

    }


    getEvent(event: any) {
        this.checklistEvents = event;
    }


    openDialog(state: string) {


        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    idFolder: this.idFolder,
                    title: this.title,
                    genericChecklist: this.genericChecklist,
                    checklistEvents: this.checklistEvents,
                    documentGender: this.mainDocument.documentsGender,
                    documentTitle: this.mainDocument.documentsTtitle,
                    documentId: this.idDocument,
                    state: state,
                    currentDocOpeningDate: this.currentDocOpeningDate,
                }
            }
        );
        dialogRef.afterClosed().subscribe(res => {
            if (res === undefined) {
                if (this.mainDocument.documentsGender == 'PHOTO_IDENTITY' || this.mainDocument.documentsGender == 'FEES_STATEMENT') {
                    this.docStateSbj$.next('REFUSED');
                    this.mainDocument.documentsState = 'REFUSED';
                }
            } else {
                this.mainDocument.documentsState = 'REFUSED';
                this.docStateSbj$.next('REFUSED');
                this.genericChecklist = res;
                this.checkSbj$.next(res);
            }
        });
    }

    changeDocumentState(state: string) {

        //PHOTO_IDENTITY && FEES_STATEMENT case just update state
        if (this.mainDocument.documentsGender === 'PHOTO_IDENTITY' || this.mainDocument.documentsGender == 'FEES_STATEMENT') {

            this.documentService.updateDocumentState(this.mainDocument.id, state, this.currentDocOpeningDate).subscribe({
                next: () => {
                    this.mainDocument.documentsState = state;
                    this.docStateSbj$.next(state);
                    const message = `le statut du document a été modifié avec succès !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                    this.nextFile();
                },
                error: () => {
                    const message = `error in updating identity state !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                }
            });

            return;
        }

        //refuse case => if user does not check any checklist => replace document check list states by corresponding events ...
        for (let key in this.genericChecklist) {
            this.genericChecklist[key] = this.checklistService.convertDocStateToEvent(this.genericChecklist[key]);
        }

        let finalCheck: GenericObject<any> = this.genericChecklist;

        this.checklistEvents.forEach((ch, i) => {
            for (let key in finalCheck) {
                if (key === ch.key) {
                    finalCheck[key] = ch.checkEventName;
                }
            }
        })

        // finalCheck=Object(this.checklistEvents);
        this.checklistService.updateChecklist(this.idFolder, this.mainDocument.documentsGender, this.mainDocument.documentsTtitle, finalCheck).subscribe({
            next: (res) => {

                this.genericChecklist = res.body;
                this.checkSbj$.next(res.body);
                //  this.router.navigateByUrl('ek-ecommerce/folder/edit/' + this.idOrder + '/' + this.idFolder + '?step=2');
                this.mainDocument.documentsState = state;
                this.docStateSbj$.next(state);

                this.documentService.updateDocumentState(this.mainDocument.id, state, this.currentDocOpeningDate).subscribe({
                    next: () => {
                        const message = `le statut du document a été modifié avec succès !`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                        this.nextFile();
                    },
                    error: () => {
                        const message = `error in updating state, try again !`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                    }
                });
            }, error: () => {
                const message = `error in updating checklist, try again !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
            }
        });


    }

    getBackGroundColor(state: string) {
        switch (state) {
            case "ACCEPTED":
                return "var(--eki-green)";
            case "REFUSED":
                return "var(--ek--cherry-red)";
            case "PENDING":
                return "var(--ek-shade-sh-light-07)";
        }
    }

    getFolderById() {
        this.folderService.getFolderById(this.idFolder).subscribe({
            next: (folder) => {
                this.folderRequestNumber$ = of(folder.requestNumber);
                this.folderSbj$.next(folder.folderState);
            }
        });
    }
}
