import {EkSnackBarService} from '../../../../../../../core/services/ek-snackBar.service';
import {StockModel} from '../../../../../../../core/ek-e-commerce/ek-models/stock.model';
import {StocksService} from '../../../../../../../core/ek-e-commerce/ek-services/stocks.service';
import {configurationQuantity} from '../../../../../../../core/ek-e-commerce/ek-models/configuration-quantity.model';
import {
    ConfigurationQuantityService
} from '../../../../../../../core/ek-e-commerce/ek-services/configuration-quantity.service';
import {ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSlideToggleChange, MatTabChangeEvent} from "@angular/material";
import {SubheaderService} from "../../../../../../../core/_base/layout";
import {ConfigurationService} from "../../../../../../../core/e-commerce";

import {
    selectConfigurationById,
    selectLastCreatedConfigurationId
} from '../../../../../../../core/e-commerce/_selectors/configuration.selector';
import {
    ConfigurationCreated,
    ConfigurationUpdated,
    ConfigurationUpdatedSuccessfully
} from '../../../../../../../core/e-commerce/_actions/configuration.action';
import {Update} from '@ngrx/entity';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {
    ConfigurationCharacteristicModel
} from '../../../../../../../core/e-commerce/_models/configuration-characteristic.model';
import {ImageModel} from '../../../../../../../core/e-commerce/_models/image.model';
import {Actions, ofType} from '@ngrx/effects';
import {EkEditImgComponent} from '../edit-img/edit-img.component';
import {EkListsCaracteristicsComponent} from "../characteristics/lists-caracteristics/lists-caracteristics.component";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {map, startWith} from "rxjs/operators";
import {coerceElement} from "@angular/cdk/coercion";
import {ConfigurationModel} from "../../../../../../../core/ek-e-commerce/ek-models/configuration.model";

@Component({
    selector: 'kt-configuration2-add',
    templateUrl: './configuration2-add.component.html',
    styleUrls: ['./configuration2-add.component.scss']
})
export class EkConfiguration2AddComponent implements OnInit {
    configuration: ConfigurationModel;
    configurationId$: Observable<number>;
    configurationId: number;
    configurationName;
    string;
    oldConfiguration: ConfigurationModel;
    selectedTab = 0;
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    configurationForm: FormGroup;
    stockForm: FormGroup;
    ekStockForm: FormGroup;
    hasFormErrors = false;
    productId: number;
    // Private password
    private componentSubscriptions: Subscription[] = [];
    // sticky portlet header margin
    private headerMargin: number;
    private updater: string;
    showAvailabilityDate: boolean;
    headerSentence: string;
    @Input() newID: number;
    refConfiguration = '';
    htmlContent = '';
    characteristicTypeShow = false;
    descriptionPossible = true;
    characteristicsSendid: ConfigurationCharacteristicModel [] = [];
    @ViewChild('tabGroup') tabGroup;
    @ViewChild(EkEditImgComponent) childImage: EkEditImgComponent;
    @ViewChild(EkListsCaracteristicsComponent) childConfigurationCharacteristic: EkListsCaracteristicsComponent;
    checked: boolean;
    indexTabBinding = 0;
    imgSended: ImageModel [] = [];

    currentState: boolean;

    currentRole = '';

    imagePath!: string | SafeUrl;
    fileName: string = 'attestation de fabrication.pdf';

    editorConfig: AngularEditorConfig = {

        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: '50%',
        minWidth: '0',
        translate: 'no',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter description ici......',
        defaultParagraphSeparator: '',
        defaultFontName: 'no',
        defaultFontSize: 'no',
        fonts: [],
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'insertImage'],
            ['insertVideo']
        ]
    };

    addingForm: boolean;
    changedFormId: number;
    isStockValid: boolean;
    isEkStockValid: boolean;
    stocks: StockModel[];
    stocksList: StockModel[];
    filteredStocks: string[];
    stocks$: Observable<StockModel[]>;
    configQua: configurationQuantity[] = [];
    isEditable: number = -1;
    canActivateStock: boolean;
    slideToggle: boolean;

    constructor(private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private configurationFB: FormBuilder,
                public dialog: MatDialog,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private configurationService: ConfigurationService,
                private cdr: ChangeDetectorRef,
                private dialogRef: MatDialogRef<EkConfiguration2AddComponent>,
                private _actions$: Actions,
                private sanitizer: DomSanitizer,
                private configurationQuantityService: ConfigurationQuantityService,
                private stocksService: StocksService,
                private ekSnackbarService: EkSnackBarService,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfigurationModel) {

                    
                this.router.events.subscribe(event => {
                    if (event instanceof NavigationEnd) {
                      this.dialogRef.close();
                    }
                  });
    }

    ngOnInit() {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        this.canActivateStock = this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN';

        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        const routerSub = this.activatedRoute.params.subscribe(params => {
            this.productId = +params['product'];
            // this.productId = 1;

        });
        this.componentSubscriptions.push(routerSub);

        this.stockForm = this.configurationFB.group({
            stocks: this.configurationFB.array([])
        });

        this.ekStockForm = this.configurationFB.group({
            stocks: this.configurationFB.array([])
        });


        this.loading$ = this.loadingSubject.asObservable();
        this.loadingSubject.next(true);
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            this.productId = id;
            if (id && id > 0) {

                this.store.pipe(
                    select(selectConfigurationById(id))
                ).subscribe(result => {
                    if (result == undefined) {
                        this.loadConfigurationFromService(id);
                        return;
                    }

                    this.loadConfiguration(result);
                });
            } else if (this.data.id && this.data.id > 0) {
                this.configuration = this.data;
                this.configurationId = this.data.id;
                this.configurationName = this.data.name;
                this.imagePath = this.configuration?.certificateUrl;
                this.fileName = this.configuration?.certificateUrl ? this.configuration.certificateUrl.substring(this.configuration.certificateUrl.lastIndexOf('/') + 1) : '';
                this.initConfiguration();
            } else if (this.data) {
                this.configuration = this.data;
                this.imagePath = this.configuration.certificateUrl;
                this.initConfiguration();

            } else {
                const newConfiguration = new ConfigurationModel();
                this.copyReference();
                newConfiguration.clear();
                this.loadConfiguration(newConfiguration);
            }

        });

        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };

        /* if (this.configuration.type === 'VIRTUAL' && this.configuration.installLink) {
             this.isVirtual_ = true;
         }*/

        this.subscribeToFormChanges();

        this.getStocksList();

        this.getConfigQuanList();

        this.stockForm.disable();
    }

    ngAfterViewInit() {
        if (this.data.images) {
            this.configuration = this.data;
            this.imgSended = this.configuration.images;

            this.childImage?.images_.next(this.configuration.images);

        }
    }


    onFileSelected(event: any) {

        if (
            (this.currentRole === 'ROLE_POS_EK' || this.currentRole == 'ROLE_GUEST_ADMIN' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')
        ) {
            return;
        }

        if (event.target.files) {

            const file: File = event.target.files[0];

            if (file.type == "application/pdf") {

                this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file)
                );
                this.fileName = file.name;

                this.configurationService.uploadManufacturingCertificate(file, this.configurationId).subscribe({
                    next: (res) => {
                        if (res?.body) this.configuration.certificateUrl = res.body;
                        //
                    }, error: () => {
                        const msg = 'veuillez réssayer !';
                        this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                    }
                });

            } else {
                const msg = 'veuillez télécharger des fichiers PDF';
                this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
            }
        }
    }

    fileDropped(event: any) {

        if (this.currentRole === 'ROLE_POS_EK' || this.currentRole == 'ROLE_GUEST_ADMIN' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {
            return;
        }

        const file: File = event.file;

        if (file.type === "application/pdf") {

            this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                window.URL.createObjectURL(file)
            );

            this.fileName = file.name;

            this.configurationService.uploadManufacturingCertificate(file, this.configurationId).subscribe({
                next: (res) => {

                }
            });

            this.loadConfigurationFromService(this.configurationId);

        } else {
            const msg = 'veuillez télécharger des fichiers PDF';
            this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
        }
    }

    // form validation of install link
    isValid() {
        return this.configurationForm.controls;
    }

    loadConfiguration(_configuration, fromService: boolean = false) {
        if (!_configuration) {
            // this.goBack('');
        }
        this.configuration = _configuration;
        this.configurationId$ = of(_configuration.id);
        this.oldConfiguration = Object.assign({}, _configuration);
        this.initConfiguration();
        if (fromService) {
            this.cdr.detectChanges();
        }
        if (!_configuration) {
            // this.goBack('');
        }

    }

    // If product didn't find in store
    loadConfigurationFromService(configurationId) {
        this.configurationService.getById(configurationId).subscribe(res => {
            this.loadConfiguration(res, true);
        });
    }


    // -------------------------------------- STOCK -------------------------------------------------------------------------

    // Stock Form
    getStocksList() {
        this.stocks$ = this.stocksService.getAll();

        this.stocksService.getAll().subscribe({
            next: (result) => {
                this.stocks = result;
                this.stocksList = result;
            },
            error: (error) => {
                if (error.error === 'unauthorized') {
                    const msg = error.error_description;
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                } else {
                    const msg = 'Connexion avec le serveur a échoué';
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                }
            }
        });
    }

    getStateByStockName(stockName: string) {
        if (this.stocks && this.configQua.length !== 0) {
            const stock = this.stocks.find(item => item.name === stockName || item.name.includes(stockName));
            const stockId = stock?.id; // Optional chaining to handle undefined stock
            if (stockId) {
                const configQua = this.configQua.find(item => item.stockDepotId === stockId)?.activated; // Optional chaining to handle undefined configQua
                return configQua === true ? configQua : false;
            }
        }
        return false;
    }


    getEkState(id) {
        if (this.configQua && this.configQua.length !== 0) {
            console.log(this.configQua);
            console.log(this.configQua.find(item => item.id === id))
            console.log(this.configQua.find(item => item.id === id)?.activated)
            return this.configQua.find(item => item.id === id)?.activated;
        }
    }


    getConfigQuanList() {
        this.configurationQuantityService.getConfigQuanByConfigId(this.configurationId).subscribe(res => {
            this.configQua = res.body;
            let index = 0;
            this.stocks$.subscribe(s => {
                if (s) {
                    res.body.forEach(stock => {
                        if (stock) {
                            const group = this.configurationFB.group({
                                id: stock.id,
                                stockName: [stock?.stockDepotId === null ? 'Ekiclik' : s.find(s => (s.id === stock.stockDepotId)).name],
                                quantity: [stock.realQuantity, Validators.compose([Validators.required, this.forbiddenPosQuantity(stock.stockName)])],
                                price: [stock.price],
                                sellingPrice: [stock.sellingPrice],
                                activated: [stock.activated],
                                configurationId: [stock.configurationId],
                                stockEkId: [stock.stockEkId],
                                stockDepotId: [stock.stockDepotId]
                            });
                            index++;
                            if (stock.stockEkId == null) {
                                this.stockFormArray.push(group);
                            } else {
                                this.stockEkFormArray.push(group);
                            }
                        }
                    });
                }
            })
            this.stockFormArray.disable()
            this.stockEkFormArray.disable()

        })

    }


    getStockControl(index: number) {
        return this.stockFormArray.at(index).get('stockName');
    }

    filterStocks(index: number) {
        this.stocksService.getAll().subscribe(result => {
            this.stocks = result;
            // Implement your filtering logic here
            this.stocks$ = this.stockFormArray.at(index).get('stockName').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this._filter(name.toString()) : this.stocks.slice())
                );
        });
    }

    private _filter(name: string): StockModel[] {
        const filterValue = name.toLowerCase();
        return this.stocks.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    watchInputChange(index: number) {
        if (index !== this.changedFormId) {
            const stockControl = this.getStockControl(index);
            this.filterStocks(index);

            stockControl.valueChanges.subscribe(value => {

            });
        } else {
            console.log('SKIP');
        }
    }


    getStockIdByName(name: string): number | undefined {
        const stock = this.stocks.find((i) => i.name === name);
        return stock?.id;
    }

    getPointOfSaleIdByName(name: string): number | undefined {
        const stock = this.stocks.find((i) => i.name === name);
        return stock?.pointOfSaleId;
    }

    saveUpdatedStock(index: number) {

        const formGroup = this.stockFormArray.at(index);
        const formData = this.stockFormArray.at(index).value as configurationQuantity;

        const prevconfigQua_ = this.configQua.find(item => item.name === formData.stockName || item.name.includes(formData.stockName));

        if ((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && prevconfigQua_ && this.stockFormArray.at(index).get('quantity').value <= prevconfigQua_.realQuantity) {
            formGroup.get('quantity').markAsTouched();
            let msg = 'La quantité de stock doit être égale ou supérieure à la précédente !!!';
            this.ekSnackbarService.openSnackBar(msg, " ", "error-snackbar");
            return;
        }

        const configQuan: configurationQuantity = {
            id: formData.id,
            stockName: formData.stockName,
            pointOfSaleId: this.getPointOfSaleIdByName(formData.stockName),
            realQuantity: this.stockFormArray.at(index).get('quantity').value,
            provisionalQuantity: this.stockFormArray.at(index).get('quantity').value,
            activated: this.configQua.find(item => item.name == formData.stockName).activated,
            configurationId: formData.configurationId,
            price: formData.price,
            stockDepotId: formData.stockDepotId,
            createdAt: '',
            updatedAt: '',
            stockEkId: 0,
            sellingPrice: formData.sellingPrice
        };

        this.configurationQuantityService.update(configQuan).subscribe(res => {
            const msg = 'Stock updated succesfully';
            this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
        });
        formGroup.disable(); // Disable the form group
        this.isEditable = -1;

    }


    forbiddenPosQuantity(stockName?: string): ValidatorFn {

        if (this.configQua && stockName) {

            const configQua_ = this.configQua.find(item => item.name === stockName || item.name.includes(stockName));

            return (control: AbstractControl): { [key: string]: any } | null => {
                return control?.value >= configQua_.realQuantity ? null : {posQuantityInValid: true};
            };

        }

    }

    openEditMode(index: number) {
        const formGroup = this.stockFormArray.at(index);
        formGroup.enable(); // Disable the form group
        this.isEditable = this.stockFormArray.at(index).get('id').value;
    }

    closeEditMode(index: number) {
        const formGroup = this.stockFormArray.at(index);
        formGroup.disable(); // Disable the form group
        this.isEditable = -1;
    }

    openEkEditMode(index: number) {
        const formGroup = this.stockEkFormArray.at(index);
        formGroup.enable(); // Disable the form group
        this.isEditable = this.stockEkFormArray.at(index).get('id').value;
    }

    closeEkEditMode(index: number) {
        const formGroup = this.stockEkFormArray.at(index);
        formGroup.disable();  // Disable the form group
        this.isEditable = -1;
    }

    toggleChange(event: MatSlideToggleChange) {
        this.slideToggle = event.checked;
    }

    saveEkUpdatedStock(index: number) {
        const formGroup = this.stockEkFormArray.at(index);
        const formData = this.stockEkFormArray.at(index).value as configurationQuantity;
        const configQuan: configurationQuantity = {
            id: formData.id,
            stockName: formData.stockName,
            pointOfSaleId: 0,
            realQuantity: this.stockEkFormArray.at(index).get('quantity').value,
            provisionalQuantity: this.stockEkFormArray.at(index).get('quantity').value,
            activated: this.slideToggle ? this.slideToggle : false,
            configurationId: formData.configurationId,
            price: formData.price,
            sellingPrice: formData.sellingPrice,
            stockDepotId: 0,
            createdAt: '',
            updatedAt: '',
            stockEkId: formData.stockEkId
        };

        this.configurationQuantityService.update(configQuan).subscribe(res => {
            const msg = 'Stock updated succesfully';
            this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
        });
        formGroup.disable();  // Disable the form group
        this.isEditable = -1;
    }


    updateState(index: number, event: MatSlideToggleChange) {

        this.slideToggle = event.checked;

        const formData = this.stockFormArray.at(index).value as configurationQuantity;

        if (formData.stockDepotId) {

            this.configurationQuantityService.changeState(
                formData.configurationId,
                0,
                formData.stockDepotId,
                event.checked
            ).subscribe({
                next: () => {

                    this.stockFormArray.at(index).get('activated').setValue(event.checked);

                    let msg = '';

                    if (event.checked) {
                        msg = `Le stock principale a été activé`;
                    } else {
                        msg = `Le stock principale a été désactivé`;
                    }

                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');

                }
            });
        }
    }

    updateStateEk(index: number, event: MatSlideToggleChange) {

        this.slideToggle = event.checked;

        const formData = this.stockEkFormArray.at(index).value as configurationQuantity;

        if (formData.stockEkId) {

            this.configurationQuantityService.changeState(
                formData.configurationId,
                1,
                0,
                event.checked
            ).subscribe({
                next: () => {

                    this.stockEkFormArray.at(index).get('activated').setValue(event.checked);

                    let msg = '';

                    if (event.checked) {
                        msg = `Le stock principale a été activé`;
                    } else {
                        msg = `Le stock principale a été désactivé`;
                    }

                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');

                }
            });
        }

    }

    createEkStockForm(): FormGroup {
        return this.configurationFB.group({
            id: [],
            stockName: ['Ekiclik', Validators.required],
            quantity: ['', Validators.required],
            price: ['', Validators.required],
            activated: [],
            stockEkId: [],
            stockDepotId: [],
            configurationId: []
        });
    }

    createStockForm(): FormGroup {
        return this.configurationFB.group({
            id: [],
            stockName: ['', Validators.required],
            quantity: ['', Validators.compose([Validators.required, this.forbiddenPosQuantity()])],
            price: ['', Validators.required],
            sellingPrice: ['', Validators.required],
            activated: [],
            stockEkId: [],
            stockDepotId: [],
            configurationId: []
        });
    }

    get stockFormArray(): FormArray {
        return this.stockForm.get('stocks') as FormArray;
    }

    get stockEkFormArray(): FormArray {
        return this.ekStockForm.get('stocks') as FormArray;
    }

    addForm() {
        this.stockFormArray.push(this.createStockForm());
        // this.isEditable = true
        // Trigger Material module initialization for newly added form controls
        setTimeout(() => {
            this.initializeMaterialModules();
            this.subscribeToFormChanges();
        });
    }

    addEkForm() {
        this.stockEkFormArray.push(this.createEkStockForm());
        // this.isEditable = true
        // Trigger Material module initialization for newly added form controls
        setTimeout(() => {
            this.initializeMaterialModules();
            this.subscribeToFormChanges();
        });
    }

    // FOR TOMMOROW
    addStockEk(index: number) {
        const formData = this.stockEkFormArray.at(index).value as configurationQuantity;
        const configQuan: configurationQuantity = {
            stockName: 'Ekiclik',
            pointOfSaleId: 0,
            realQuantity: this.stockEkFormArray.at(index).get('quantity').value,
            provisionalQuantity: this.stockEkFormArray.at(index).get('quantity').value,
            activated: this.slideToggle ? this.slideToggle : false,
            configurationId: this.configurationId,
            price: formData.price,
            sellingPrice: formData.sellingPrice,
            stockDepotId: 0,
            createdAt: '',
            updatedAt: '',
            stockEkId: 1
        };

        this.configurationQuantityService.save(configQuan).subscribe({
            next: () => {
                this.ekStockForm.disable();
                const msg = 'Stock Ekiclik ajouté avec succés';
                this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
            },
            error: (error) => {
                if (error.error.message === 'this configuration exists in this depot' && error.error.status === 500) {
                    const msg = 'cette configuration existe dans ce dépôt déjà !';
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                } else {
                    const msg = 'Error in adding Stock Ekiclik';
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                }
            }
        });

    }

    deleteStock(id: number, index: number) {
        if (id > 0) {
            this.configurationQuantityService.delete(id).subscribe(res => {
                this.stockFormArray.removeAt(index);
                const msg = 'Stock deleted successfully';
                this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
            });
            setTimeout(() => {
                this.initializeMaterialModules();
                this.subscribeToFormChanges();
            });
        } else {
            this.stockFormArray.removeAt(index);
        }
    }

    // Had to add this because form gets generated without mat label and stuff
    private initializeMaterialModules() {
        const formFields = document.querySelectorAll('.mat-form-field');
        formFields.forEach((formField) => {
            const element = coerceElement(formField);
            if (element && !element.classList.contains('ng-untouched')) {
                element.classList.add('ng-untouched');
            }
        });
    }

    addStock(index: number) {
        const formData = this.stockFormArray.at(index).value as configurationQuantity;
        const configQuan: configurationQuantity = {
            stockName: formData.stockName,
            pointOfSaleId: this.getPointOfSaleIdByName(formData.stockName),
            realQuantity: this.stockFormArray.at(index).get('quantity').value,
            provisionalQuantity: this.stockFormArray.at(index).get('quantity').value,
            activated: this.slideToggle ? this.slideToggle : false,
            configurationId: this.configurationId,
            price: formData.price,
            sellingPrice: formData.sellingPrice,
            stockDepotId: this.getStockIdByName(formData.stockName),
            createdAt: '',
            updatedAt: '',
            stockEkId: 0
        };

        this.configurationQuantityService.save(configQuan).subscribe({
            next: (res) => {
                this.stockForm.disable();
                const msg = 'Stock added successfully';
                this.ekSnackbarService.openSnackBar(msg, '', 'error-snackbar');
            },
            error: (error) => {
                if (error.error.message === 'this configuration exists in this depot' && error.error.status === 500) {
                    const msg = 'cette configuration existe dans ce dépôt déjà !';
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                } else {
                    const msg = 'error in adding Stock !';
                    this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
                }
            }
        });
    }

    subscribeToFormChanges() {
        const myArrayControl = this.stockForm.get('stocks') as FormArray;
        const myEkArrayControl = this.ekStockForm.get('stocks') as FormArray;
        myArrayControl.controls.forEach((control, index) => {
            control.valueChanges.subscribe(() => {
                this.checkValidity(index);
                this.watchInputChange(index);
                this.changedFormId = index;
            });
        });
        myEkArrayControl.controls.forEach((control, index) => {
            control.valueChanges.subscribe(() => {
                this.checkEkValidity(index);
                this.changedFormId = index;
            });
        });
    }

    checkValidity(index: number) {
        const myArrayControl = this.stockForm.get('stocks') as FormArray;
        const formControl = myArrayControl.at(index);
        this.isStockValid = formControl.valid;
    }

    checkEkValidity(index: number) {
        const myArrayControl = this.ekStockForm.get('stocks') as FormArray;
        const formControl = myArrayControl.at(index);
        this.isEkStockValid = formControl.valid;
    }


    // --------------------------------------------- END STOCKS -----------------------------------------------------------------------

    /**
     * Init configuration
     */
    initConfiguration() {
        this.createForm();
        this.loadingSubject.next(false);
        if (!this.configuration.id) {
            this.subheaderService.setBreadcrumbs([
                {title: 'eCommerce', page: `/ecommerce`},
                {title: 'Configurations', page: `/ecommerce/configurations`},
                {title: 'Create configuration', page: `/ecommerce/configurations/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit configuration');
        this.subheaderService.setBreadcrumbs([
            {title: 'eCommerce', page: `/ecommerce`},
            {title: 'Configurations', page: `/ecommerce/configurations`},
            {
                title: 'Edit configuration',
                page: `/ecommerce/configurations/edit`,
                queryParams: {id: this.configuration.id}
            }
        ]);
    }

    /**
     * Create form
     */
    createForm() {

        let date: Date;
        if (this.configuration.availabilityDate == null) {
            date = new Date();
        } else {
            date = new Date(this.configuration.availabilityDate);
        }

        this.configurationForm = this.configurationFB.group({
            name: [this.configuration.name, Validators.required],
            price: [this.configuration.price, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            sellingPrice: [this.configuration.price, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            buyingPrice: [this.configuration.buyingPrice],
            referConfiguration: [this.configuration.reference],
            state: ['EN_STOCK', Validators.required],
            availabilityDate: [date, Validators.required],
        });


        if (this.configuration.state == 'BIENTOT_DISPONIBLE') {
            this.showAvailabilityDate = true;
        }
        if (this.data.id && this.data.id > 0) {
            this.refConfiguration = this.data.reference;
            this.htmlContent = this.data.description;
            this.configurationForm.controls.state.setValue(this.configuration.state);

        } else {
            this.copyReference();
        }
    }

    /**
     * Go back to the list
     *
     * @param id: any
     */

    // todo we need to go back to the correct link
    goBack() {
        this.loadingSubject.next(false);
        const url = `/ek-ecommerce/products/edit/${this.productId}?tab=1`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    goBackWithoutId() {
        this.router.navigateByUrl('/ek-ecommerce/products', {relativeTo: this.activatedRoute});
    }

    /**
     * Refresh configuration
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshConfiguration(isNew: boolean = false, id = 0) {
        this.loadingSubject.next(false);
        let url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }

        url = `/ek-ecommerce/products/edit/${id}`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    /**
     * Reset
     */
    reset() {
        this.configuration = Object.assign({}, this.oldConfiguration);
        this.createForm();
        this.hasFormErrors = false;
        this.configurationForm.markAsPristine();
        this.configurationForm.markAsUntouched();
        this.configurationForm.updateValueAndValidity();
    }

    /**
     * Save data
     *
     * @param withBack: boolean
     */
    onSumbit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.configurationForm.controls;
        /** check form */
        if (this.configurationForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }

        // tslint:disable-next-line:prefer-const
        let editedConfiguration = this.prepareConfiguration();
        editedConfiguration.updater = this.updater;

        if (editedConfiguration.id > 0) {
            this.updateConfiguration(editedConfiguration, withBack);
            return;
        }

    }

    /**
     * Returns object for saving
     */
    prepareConfiguration(): ConfigurationModel {
        const controls = this.configurationForm.controls;
        const _configuration = new ConfigurationModel();
        _configuration.id = this.configuration.id;
        _configuration.price = controls.price.value;
        _configuration.state = controls.state.value;
        _configuration.productId = this.configuration.productId;
        _configuration.name = controls.name.value;
        _configuration.buyingPrice = controls.buyingPrice.value;
        _configuration.availabilityDate = controls.availabilityDate.value;
        _configuration.reference = controls.reference.value;

        return _configuration;
    }

    /**
     * Add configuration
     *
     * @param _configuration: ConfigurationModel
     * @param withBack: boolean
     */
    addConfiguration() {

        if (this.configurationForm.invalid) {
            this.configurationForm.markAsTouched();
            const message = `Merci de remplir tous les champs obligatoires!`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
            return;
        }

        const controls = this.configurationForm.controls;
        const _configuration = new ConfigurationModel();
        _configuration.id = this.configuration.id;
        _configuration.price = controls.price.value;
        _configuration.state = controls.state.value;
        _configuration.productId = this.configuration.productId;
        _configuration.name = controls.name.value;
        _configuration.buyingPrice = controls.buyingPrice.value;
        _configuration.description = this.htmlContent;
        _configuration.availabilityDate = controls.availabilityDate.value;
        _configuration.active = false;
        _configuration.reference = controls.referConfiguration.value;
        _configuration.productCharacteristics = this.characteristicsSendid;
        _configuration.images = this.imgSended;
        _configuration.updater = this.updater;
        _configuration.certificateUrl = this.configuration.certificateUrl;

        if (this.htmlContent.length < 22001) {
            this.descriptionPossible = true;
            if (this.data.id && this.data.id > 0) {
                _configuration.productId = this.data.productId;
                _configuration.updater = this.updater;
                this.updateConfiguration(_configuration);
            } else {

                this.configurationService.nameExists(controls.name.value).subscribe(
                    (e) => {
                        _configuration.productId = this.data.productId;
                        if (!e) {
                            if (_configuration.productId && _configuration.productId > 0) {
                                _configuration.updater = this.updater;
                                this.addConfigurationIsproductexist(_configuration);

                            } else {
                                this.dialogRef.close({data: _configuration});
                            }

                        } else {
                            this.headerSentence = 'Nom déjà existant !';
                        }
                    }
                );
            }
        } else {
            this.descriptionPossible = false;
        }


    }

    addConfigurationIsproductexist(c) {
        this.store.dispatch(ConfigurationCreated({configuration: c}));
        const storeSub = this.store.pipe(
            // delay(1000),
            select(selectLastCreatedConfigurationId)
        ).subscribe(newId => {
            if (!newId) {
                return;
            }

            this.loadingSubject.next(false);
            if (false) {
                // this.goBack(newId);
            } else {
                const message = `New configuration successfully has been added.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
                this.dialogRef.close({data: c});
                // this.refreshConfiguration(true, this.productId);
            }
        });
    }

    /***
     * Update configuration
     *
     * @param _configuration: ConfigurationModel
     * @param withBack: boolean
     */
    updateConfiguration(_configuration: ConfigurationModel, withBack: boolean = false) {

        _configuration.updater = this.updater;
        _configuration.images = this.imgSended;
        _configuration.productCharacteristics = this.characteristicsSendid;

        const updatedConfiguration: Update<ConfigurationModel> = {
            id: _configuration.id,
            changes: _configuration
        };

        this.store.dispatch(ConfigurationUpdated({
            partialConfiguration: updatedConfiguration,
            configuration: _configuration
        }));

        this._actions$.pipe(ofType(ConfigurationUpdatedSuccessfully)).subscribe((data: any) => {
            if (data) {
                const message = `Configuration successfully has been saved.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
                this.loadConfigurationFromService(_configuration.id);
            }
        });

        if (withBack) {
            // this.goBack(_configuration.id);
        } else {
            this.dialogRef.close({data: _configuration});
        }
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        let result = 'Create configuration';
        if (!this.configuration || !this.configuration.id) {
            return result;
        }

        result = `Edit configuration - ${this.configuration.id}`;
        return result;
    }

    /**
     * Close alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    activateAvailabilityDate(bool) {
        if (bool == 'BIENTOT_DISPONIBLE') {
            this.showAvailabilityDate = true;
        } else {
            this.showAvailabilityDate = false;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    copyReference() {
        this.configurationService.generateReference().subscribe(res => {
            this.refConfiguration = res["body"];


        });

    }

    displayCharacteristique(char) {
        this.characteristicsSendid = char;
        this.configuration.productCharacteristics = this.characteristicsSendid;

    }

    displayImage(img) {

        this.imgSended = img;
        this.configuration.images = this.imgSended;

    }

    /**
     * dialogue closed
     */
    exist() {
        this.dialogRef.close();
    }


    /**
     switching mat table group
     *
     */
    tabChanged(tabChangeEvent
                   :
                   MatTabChangeEvent
    ):
        void {
        this.indexTabBinding = tabChangeEvent.index;
        if (this.data.id && this.data.id > 0
        ) {
        } else {
            if (this.childImage != null) {
                this.childImage.images_.next(this.imgSended);
            }
            if (this.childConfigurationCharacteristic != null) {

                this.childConfigurationCharacteristic.characteristicsData = this.characteristicsSendid;
                this.childConfigurationCharacteristic.dataSource.data = this.characteristicsSendid;
            }
        }

    }

    nextTab() {
        this.indexTabBinding++;
    }

    previousTab() {
        this.indexTabBinding--;
    }

    /**
     * switch between  characteristic Config && characteristic type
     */
    show() {
        this.characteristicTypeShow = true;
    }

    back() {
        this.characteristicTypeShow = false;
    }

    onKeyUp() {
        const length = this.htmlContent.length;
        this.descriptionPossible = length < 22001;
    }


    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }
}
