import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {fromEvent, merge, Subscription} from "rxjs";
import {AppState} from "../../../../../../core/reducers";
import {Store} from "@ngrx/store";
import {debounceTime, distinctUntilChanged, filter, skip, tap} from "rxjs/operators";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../core/_base/crud";
import {SelectionModel} from "@angular/cdk/collections";
//Actions
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {Update} from "@ngrx/entity";
import {NgxPermissionsService} from "ngx-permissions";
import {DatePipe} from "@angular/common";
import {EkCategoryEditComponent} from "../ek-category-edit/ek-category-edit.component";
import {EKCategoriesDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/ek-categories.datasource";
import {EkCategoryModel} from "../../../../../../core/ek-e-commerce/ek-models/ek-category.model";
import {selectError, selectlastAction} from "../../../../../../core/ek-e-commerce/ek-selectors/ek-category.selector";
import * as CategoryActions from "../../../../../../core/ek-e-commerce/ek-actions/ek-category.action";

@Component({
    selector: 'kt-ek-category-list',
    templateUrl: './ek-category-list.component.html',
    styleUrls: ['./ek-category-list.component.scss']
})
export class EkCategoryListComponent implements OnInit {

    //Table fields
    dataSource: EKCategoriesDatasource;
    displayedColumns = ['select', 'id', 'label', 'labelArabic', 'parentId', 'createdAt', 'updatedAt', 'updater', 'edit'];
    //Table Data
    catogoriesResult: EkCategoryModel[] = [];
    checked: boolean = false;
    updater: string = '';


    //Permissions
    private PERMISSIONS = ['ALL_PRODUCT', 'UPDATE_PRODUCT'];
    canEdit = false;


    //Paginator
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    //Sort
    @ViewChild('sort1', {static: true}) sort: MatSort;
    //Filter
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    filterStatus = '';
    filterType = '';
    //Selection
    selection = new SelectionModel<EkCategoryModel>(true, []);


    //Subscriptions
    currentRole: string = '';
    private subscriptions: Subscription[] = [];


    constructor(
        private store: Store<AppState>,
        private translate: TranslateService,
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private ngxPermissionService: NgxPermissionsService,
        public datepipe: DatePipe
    ) {
    }

    ngOnInit() {
        /* Data load will be triggered in two cases:
            - when a pagination event occurs => this.paginator.page
            - when a sort event occurs => this.sort.sortChange
            **/

        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        this.paginator._changePageSize(100);
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadCategoryList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadCategoryList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        this.loadCategoryList();

        this.dataSource = new EKCategoriesDatasource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(0),
            distinctUntilChanged()
        ).subscribe(res => {
            this.catogoriesResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        //subscribing to the error observable
        const error$ = this.store.select(selectError).pipe(filter(result => result !== null)).subscribe(result => {
            this.layoutUtilsService.showActionNotification(this.translate.instant('ECOMMERCE.CATEGORIES.' + result.message), MessageType.Delete);
        });
        this.subscriptions.push(error$);
        //subscribing to the seccessOperations
        const operation$ = this.store.select(selectlastAction).pipe(filter(result => result !== '')).subscribe(result => {
            this.layoutUtilsService.showActionNotification(this.translate.instant('ECOMMERCE.CATEGORIES.' + result), MessageType.Delete, 10000, true, false);
        });
        this.subscriptions.push(operation$);

        this.checkPermissionToUpdate();

    }

    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });
    }

    loadCategoryList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(CategoryActions.CategoriesPageRequested({
            page: queryParams
        }));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    /** FILTRATION */
    filterConfiguration(): string {
        // const filter: any = {};
        // filter.label = searchText;
        return this.searchInput.nativeElement.value;
    }

    masterToggle() {
        if (this.selection.selected.length === this.catogoriesResult.length) {
            this.selection.clear();
        } else {
            this.catogoriesResult.forEach(row => this.selection.select(row));
        }
    }

    /**
     * Check all rows are selected
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.catogoriesResult.length;
        return numSelected === numRows;
    }

    editCategory(category: EkCategoryModel) {
        const dialogRef = this.dialog.open(EkCategoryEditComponent, {
            data: {
                category,
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.loadCategoryList();
        });
    }

    /**
     * Show add category dialog
     */
    addCategory() {
        const newCategory = new EkCategoryModel();
        newCategory.clear(); // Set all defaults fields
        this.editCategory(newCategory);
    }

    deleteCategory(category: EkCategoryModel) {

        const _title: string = this.translate.instant('ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.TITLE');
        const _description: string = this.translate.instant('ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.WAIT_DESCRIPTION');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(CategoryActions.CategoryDeleted({categoryId: category.id}));

        });

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     update category.activate
     */

    OnChangeActivateCategory(category: EkCategoryModel, value: boolean, updater: string) {
        const _category = new EkCategoryModel();
        _category.id = category.id;
        _category.activate = value;
        _category.updater = updater;
        var today = new Date();
        _category.updatedAt = this.datepipe.transform(today, 'yyyy-MM-dd');
        const updateCategory: Update<EkCategoryModel> = {
            id: category.id,
            changes: _category
        };


        this.store.dispatch(CategoryActions.CategoryUpdatedActivate({
            category: _category,
            partialCategory: updateCategory,
            activate: value,
            updater: updater
        }));


        const message = `Category successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


    }

    onChange(value, category: EkCategoryModel, updater) {
        if (value.checked === true) {
            this.OnChangeActivateCategory(category, true, updater);
            this.checked = true;
        }
        if (value.checked === false) {
            this.checked = false;
            this.OnChangeActivateCategory(category, false, updater);

        }
    }

}
