import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentModel} from '../../../../../../../../core/ek-e-commerce/ek-models/document.model';
import {GenericObject} from '../../../../../../../../core/ek-e-commerce/ek-models/generic-object.model';
import {DocumentService} from '../../../../../../../../core/services/document.service';

@Component({
    selector: 'kt-document-check-list',
    templateUrl: './document-check-list.component.html',
    styleUrls: ['./document-check-list.component.scss']
})
export class DocumentCheckListComponent implements OnInit {

    @Input() document: DocumentModel;
    @Input() genericChecklist: GenericObject<any>;
    @Input() folderState = '';
    @Output() checkItem = new EventEmitter<GenericObject<any>>();
    @Output() checklistEvents = new EventEmitter<{ key: string, checkEventName: string }[]>();
    finalState: string;
    emitCheckObj: GenericObject<any>;
    eventList: { key: string, checkEventName: string }[] = [];
    currentRole = '';

    constructor(private documentService: DocumentService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.documentService.currentDocSbj$.subscribe(document => {
            if (document) {
                this.document = document;
                this.emitCheckObj = this.genericChecklist;
            }
        });


    }

    isCheckedManually(name: string) {
        return name === 'OCR_VALIDATED_MANUAL_VALIDATED' ||
            name === 'MANUAL_VALIDATED' ||
            name === 'OCR_NOT_VALIDATED_MANUAL_VALIDATED' ||
            name === 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED' ||
            name === 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED' ||
            this.document.documentsState === 'ACCEPTED';
    }

    isCheckedAutomatically(name: string) {
        return name === 'OCR_VALIDATED' ||
            name === 'OCR_VALIDATED_MANUAL_VALIDATED' ||
            name === 'OCR_VALIDATED_MANUAL_NOT_VALIDATED';

    }

    canChangeDoc() {
        switch (this.currentRole) {
            case 'ROLE_BANKER_EK' :
                return true;
            case 'ROLE_COMMERCIAL_REGION_MANAGER' :
                return true;
            case 'ROLE_CONFORMITY_MANAGER' :
                return true;
            case 'ROLE_GUEST_ADMIN':
                return true;
            case 'ROLE_CREDIT_ANALYST_EK' :
                return this.folderState !== 'EK_BANK_RESERVE'
                    && this.folderState !== 'EK_FOLDER_IN_PROGRESS'
                    && this.folderState !== 'EK_GUARTENTEES_PENDING_SIGNATURE'
                    && this.folderState !== 'EK_GUARTENTEES_SIGNED_IN_PROGRESS';

            default :
                return false;
        }
    }


    onChange(event, checkObj) {
        let checkPayload: GenericObject<any>;
        let checkListEvent: string;
        let checkEvent: { key: string, checkEventName: string };
        if (event.checked === true) {
            switch (checkObj.value) {
                case 'OCR_VALIDATED':
                    this.finalState = 'OCR_VALIDATED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_V_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: 'OCR_V_TO_MANUAL_V'
                    }
                    break;
                case 'OCR_NOT_VALIDATED':
                    this.finalState = 'OCR_NOT_VALIDATED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_NV_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED':
                    this.finalState = 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                        event: ''
                    }
                    checkListEvent = 'NOT_OCR_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_VALIDATED_MANUAL_NOT_VALIDATED':
                    this.finalState = 'OCR_VALIDATED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_V_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_VALIDATED_MANUAL_NOT_VALIDATED':
                    this.finalState = 'OCR_NOT_VALIDATED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_NV_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_SUPPORTED_MANUAL_NOT_VALIDATED':
                    this.finalState = 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_NS_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'NOT_AFFECTED_BY_OCR':
                    this.finalState = 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'NOT_OCR_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_SUPPORTED':
                    this.finalState = 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_NS_TO_MANUAL_V';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                default:
                    checkPayload = {
                        key: checkObj.key,
                        value: 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED'
                    }
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: 'OCR_NS_TO_MANUAL_V'
                    }
            }
        } else {
            switch (checkObj.value) {
                case 'OCR_VALIDATED':
                    this.finalState = 'OCR_VALIDATED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_V_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_VALIDATED':
                    this.finalState = 'OCR_NOT_VALIDATED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_NV_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                /*case 'MANUAL_VALIDATED':
                  this.finalState = 'MANUAL_NOT_VALIDATED';
                  checkPayload = {
                    key: checkObj.key,
                    value: this.finalState
                  }
                  break;*/
                case 'OCR_VALIDATED_MANUAL_VALIDATED':
                    this.finalState = 'OCR_VALIDATED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_V_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED':
                    this.finalState = 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'NOT_OCR_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED':
                    this.finalState = 'OCR_NOT_SUPPORTED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_NS_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_VALIDATED_MANUAL_VALIDATED':
                    this.finalState = 'OCR_NOT_VALIDATED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_NV_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED':
                    this.finalState = 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'NOT_OCR_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'MANUAL_NOT_VALIDATED':
                    this.finalState = 'MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_NS_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_VALIDATED_MANUAL_NOT_VALIDATED':
                    this.finalState = 'OCR_VALIDATED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState
                    }
                    checkListEvent = 'OCR_V_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'NOT_AFFECTED_BY_OCR':
                    this.finalState = 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'NOT_OCR_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                case 'OCR_NOT_SUPPORTED':
                    this.finalState = 'OCR_NOT_SUPPORTED_MANUAL_NOT_VALIDATED';
                    checkPayload = {
                        key: checkObj.key,
                        value: this.finalState,
                    }
                    checkListEvent = 'OCR_NS_TO_MANUAL_NV';
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: checkListEvent
                    }
                    break;
                default:
                    checkPayload = {
                        key: checkObj.key,
                        value: 'OCR_NOT_SUPPORTED_MANUAL_NOT_VALIDATED'
                    }
                    checkEvent = {
                        key: checkObj.key,
                        checkEventName: 'OCR_NS_TO_MANUAL_NV'
                    }
            }
        }
        this.eventList = this.eventList.filter((item) => item.key !== checkEvent.key)
        this.eventList.push(checkEvent);
        this.checklistEvents.emit(this.eventList)
        for (let key in this.emitCheckObj) {
            if (key === checkPayload.key) {
                this.emitCheckObj[key] = checkPayload.value;
            }
        }
        this.checkItem.emit(this.emitCheckObj);
    }
}
