import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'kt-confirm-duplicate-dialog',
  templateUrl: './confirm-duplicate-dialog.component.html',
  styleUrls: ['./confirm-duplicate-dialog.component.scss']
})
export class ConfirmDuplicateDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<ConfirmDuplicateDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }


}
