import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DiscountService} from '../../../../../../../../core/ek-e-commerce/ek-services/discount.service';
import {DiscountHistoryModel} from '../../../../../../../../core/ek-e-commerce/ek-models/discountHistory.model';
import {MatTableDataSource} from '@angular/material/table';
import {RolesTable} from '../../../../../../../../core/auth/_server/roles.table';

@Component({
    selector: 'kt-discount-history',
    templateUrl: './discount-history.component.html',
    styleUrls: ['./discount-history.component.scss']
})
export class DiscountHistoryComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    folderId: number,
                    requestNumber: string
                },
                public dialogRef: MatDialogRef<DiscountHistoryComponent>,
                private discountService: DiscountService) {
    }

    displayedColumns: string[] = ['configurationName', 'discount', 'update-date', 'by-user'];
    dataSource: MatTableDataSource<DiscountHistoryModel>; // Data source

    discountHistoryData: DiscountHistoryModel [] = [];

    close() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.getAllPaged();
    }

    translateRole(role) {
        return RolesTable.roles.find(item => item.id === role).title;
    }

    getAllPaged() {
        this.discountService.getDiscountHistoryByFolderId(this.data.folderId).subscribe(res => {
            this.discountHistoryData = res['content'];
            // init dataSource
            this.dataSource = new MatTableDataSource(this.discountHistoryData);
        });
    }
}
