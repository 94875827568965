// Angular
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Material
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
// RXJS
import {catchError, debounceTime, distinctUntilChanged, map, skip, tap} from 'rxjs/operators';
import {BehaviorSubject, fromEvent, merge, of, Subscription} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
// UI
import {SubheaderService} from '../../../../../../core/_base/layout';
// CRUD
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
// Services and Models
//Actions
import {ProductModel, ProductsService,} from '../../../../../../core/e-commerce';
import {Update} from "@ngrx/entity";

import {ConfigurationsDatasource} from "../../../../../../core/e-commerce/_data-sources/configuration.datasource";
import {
    ConfigurationDeleted,
    ConfigurationUpdateActivateFailed,
    ConfigurationUpdatedActivate,
    ConfigurationUpdatedActivateSuccessfully, displayConfiguration,
    displayConfigurationFailed,
    displayConfigurationSuccessfully,
    search,
} from "../../../../../../core/e-commerce/_actions/configurations.actions";
import {ConfigurationService} from "../../../../../../core/e-commerce/_services/confguration.service";
import {EkProductsListDialogComponent} from "../products-list-dialog/products-list-dialog.component";
import {FormControl, FormGroup} from "@angular/forms";
import {
    EkProductsCharacteristicsDialogComponent
} from "../products-characteristics-dialog/products-characteristics-dialog.component";
import {HttpClient, HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {selectProductsPageLastQuerys2} from "../../../../../../core/e-commerce/_selectors/configurations.selector";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {CategoriesService} from "../../../../../../core/e-commerce";
import {CategoryModel} from "../../../../../../core/e-commerce/_models/category.model";
import {ResizeEvent} from "angular-resizable-element";
//images
import {ImagesService} from "../../../../../../core/e-commerce/_services/images.service";
import * as XLSX from 'xlsx';
import {Actions, ofType} from "@ngrx/effects";
import {ConfigurationModel} from "../../../../../../core/ek-e-commerce/ek-models/configuration.model";

@Component({
    selector: 'kt-products2-list',
    templateUrl: './products2-list.component.html',
    styleUrls: ['./products2-list.component.scss']
})
export class EKProducts2ListComponent implements OnInit {
// Table fields
    dataSource: ConfigurationsDatasource;
    // displayedColumns = ['select','configurationName','productName','price','status','image','actions','settings'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    filterStatus = '';
    filterCondition = '';
    filterCategoryId = null;
    lastQuery: QueryParamsModel;
    // Selection
    selection = new SelectionModel<ConfigurationModel>(true, []);
    productsResult: ProductModel;
    configurationsResult: ConfigurationModel[] = [];
    config: ConfigurationModel;
    private subscriptions: Subscription[] = [];
    checked: boolean = false;
    showImportExportSpinner: boolean = false;
    filterByDateActivated = false;
    showDatePicker = true;
    loadingData = false;
    defaultWidth = 200
    matTableMaxHeight;
    private updater: string;
    // range date
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });

    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null;
    toDate: NgbDate | null;


    // XLSX
    title = 'XlsRead';
    file: File;
    arrayBuffer: any;
    filelist: any;
    progress$ = new BehaviorSubject(0);
    productsNumber = 0;
    loadingImages = false;


    filterByStatus = new FormControl();
    filterByStatusList = [
        {name: 'EN STOCK', value: 'EN_STOCK'},
        {name: 'SUR COMMANDE', value: 'SUR_COMMANDE'},
        {name: 'RUPTURE DE STOCK', value: 'RUPTURE_DE_STOCK'},
        {name: 'Non disponible', value: 'OBSOLETE'}
    ]

    filterByActive = new FormControl();
    filterByActiveList = [
        {name: 'Tous', value: null},
        {name: 'Produits activés', value: true},
        {name: 'Produits désactivés', value: false}
    ]
    filterByOrdered = new FormControl();
    filterByOrderedList = [
        {name: 'Tous', value: null},
        {name: 'Produits commandés', value: true},
        {name: 'Produits non commandés', value: false}
    ]
    columns = [
        {
            columnDef: 'start',
            width: this.defaultWidth,
            header: '',
            cell: (element: ConfigurationModel) => '',
            selected: true
        },
        {
            columnDef: 'id',
            width: 80,
            header: 'id',
            type: '',
            cell: (element: ConfigurationModel) => `${element.id}`,
            selected: true
        },
        {
            columnDef: 'name',
            width: this.defaultWidth,
            header: 'nomConfiguration',
            type: '',
            cell: (element: ConfigurationModel) => `${element.name}`,
            selected: true
        },
        {
            columnDef: 'product.name',
            width: this.defaultWidth,
            header: 'nomProduit',
            type: '',
            cell: (element: ConfigurationModel) => `${element.productName}`,
            selected: true
        },
        {
            columnDef: 'images',
            width: this.defaultWidth,
            header: 'images',
            type: '',
            cell: (element: ConfigurationModel) => `${element.images[0].url}`,
            selected: true
        },
        {
            columnDef: 'createdAt',
            width: this.defaultWidth,
            header: 'date d\'entrée',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.createdAt}`,
            selected: false
        },
        {
            columnDef: 'reference',
            width: this.defaultWidth,
            header: 'référence',
            type: '',
            cell: (element: ConfigurationModel) => `${element.reference}`,
            selected: false
        },
        {
            columnDef: 'state',
            width: this.defaultWidth,
            header: 'Status',
            type: '',
            cell: (element: ConfigurationModel) => `${element.state}`,
            selected: true
        },
        {
            columnDef: 'detede',
            width: this.defaultWidth,
            header: 'date du début (remise)',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.discount ? element.discount.startingDate : ''}`,
            selected: false
        },
        {
            columnDef: 'detein',
            width: this.defaultWidth,
            header: 'date de fin (remise)',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.discount ? element.discount.endingDate : ''}`,
            selected: false
        },
        {
            columnDef: 'remise',
            width: this.defaultWidth,
            header: 'valeur de la remise',
            type: '',
            cell: (element: ConfigurationModel) => `${element.discount ? element.discount.value : ''}`,
            selected: false
        },
        {
            columnDef: 'updater',
            width: this.defaultWidth,
            header: 'updater',
            type: '',
            cell: (element: ConfigurationModel) => `${element.updater}`,
            selected: false
        },
        {
            columnDef: 'updatedAt',
            width: this.defaultWidth,
            header: 'date de dernier changement',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.updatedAt}`,
            selected: false
        },
        {
            columnDef: 'price',
            width: this.defaultWidth,
            header: 'prix affiché',
            type: '',
            cell: (element: ConfigurationModel) => `${element.price}`,
            selected: false
        },
        {
            columnDef: 'buyingPrice',
            width: this.defaultWidth,
            header: 'prix d\'achat',
            type: '',
            cell: (element: ConfigurationModel) => `${element.buyingPrice}`,
            selected: false
        },
        {
            columnDef: 'product.brand.name',
            width: this.defaultWidth,
            header: 'Marque',
            type: '',
            cell: (element: ConfigurationModel) => `${element.productBrand}`,
            selected: true
        },

        {
            columnDef: 'categoryLabel',
            width: this.defaultWidth,
            header: 'categorie',
            type: '',
            cell: (element: ConfigurationModel) => `${element.categoryLabel}`,
            selected: false
        },
        {
            columnDef: 'idPoduct',
            width: this.defaultWidth,
            header: 'idProduit',
            type: '',
            cell: (element: ConfigurationModel) => `${element.productId}`,
            selected: true
        },
        // { columnDef: 'Actions', header: 'Actions' ,  cell: (element: any ) => ``, selected: true },
        {columnDef: 'end', width: 150, header: 'Actions', cell: (element: any) => ``, selected: true},
    ]

    currentRole = '';

    displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);

    filterValue: any = {};
    /**
     * Component constructor
     *
     * @param dialog: MatDialog
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param subheaderService: SubheaderService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store<AppState>
     */
    @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

    @ViewChild("fileUploadImages", {static: false}) fileUploadImages: ElementRef;

    files = [];

    constructor(
        public formatter: NgbDateParserFormatter,
        private calendar: NgbCalendar,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private productService: ProductsService,
        private configurationService: ConfigurationService,
        private categoryService: CategoriesService,
        private _dialog: MatDialog,
        private _actions$: Actions,
        private store: Store<AppState>,
        private imageService: ImagesService,
        private cdr: ChangeDetectorRef,
        private http: HttpClient  ) {

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

    }

    keyword = 'label';

    data: CategoryModel[];

    ngOnInit() {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.getTableMaxHeight()
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => {

            (this.paginator.pageIndex = 0);

        });
        this.subscriptions.push(sortSubscription);

        this.categoryService.getAll().subscribe(res => {

            this.data = res
        })

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        this.paginator._changePageSize(100);
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {

                this.loadProductsList()
            })
        ).subscribe();

        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap((v) => {

                this.paginator.pageIndex = 0;
                this.loadProductsList();
            })
        ).subscribe();

        this.subscriptions.push(searchSubscription);

        // Set title to page breadCrumbs
        this.subheaderService.setTitle('Products');

        // Init DataSource
        this.dataSource = new ConfigurationsDatasource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.getSavedListSetting();
            this.configurationsResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        const lastQuerySubscription = this.store.pipe(select(selectProductsPageLastQuerys2)).subscribe(res => this.lastQuery = res);
        // Load last query from store
        this.subscriptions.push(lastQuerySubscription);

        // Read from URL itemId, for restore previous state
        const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (params.id) {

                this.restoreState(this.lastQuery, +params.id);
            }
            // First load

            this.loadProductsList();
        });
        this.subscriptions.push(routeSubscription);

        this.fieldListener();
        this.filterByActivate();
        this.filterByOrderedFunc();

        this.matTableMaxHeight = 287;
    }


    onClick() {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.onchange = () => {
            for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({data: file, inProgress: false, progress: 0});
            }
            this.uploadFiles();
        };
        fileUpload.click();
    }

    private uploadFiles() {
        this.fileUpload.nativeElement.value = '';
        this.files.forEach(file => {
            this.uploadFile(file);
        });
    }

    uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file.data);
        file.inProgress = true;
        this.configurationService.upload(formData).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                file.inProgress = false;
                return of(`${file.data.name} upload failed.`);
            })).subscribe((event: any) => {
            // if (typeof (event) === 'object') {
            //    console.log(event)
            this.loadProductsList();
        });
    }

    exportProductsExcel() {
        this.showImportExportSpinner = true
        this.configurationService.ExportExcelProducts().subscribe((response: any) => {
            let blob: any = new Blob([response], {type: 'text/json; charset=utf-8'});
            // @ts-ignore
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            link.download = 'Produits.xlsx';
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100)
            this.showImportExportSpinner = false;
            this.cdr.detectChanges()
        })
    }

    private fieldListener() {
        this.filterByStatus.valueChanges
            .subscribe(
                value => {
                    this.loadProductsList();
                }
            )
    }


    disableFilterByDate(event) {
        if (event.checked == false) {
            this.filterByDateActivated = false;
            this.fromDate = null;
            this.toDate = null;
            this.loadProductsList();
        }
    }

    openInNewPage(c: ConfigurationModel) {

        this.router.navigate(['/ek-ecommerce/products2/edit/' + c.productId]);

    }

    /**this.formatDate(this.fromDate)
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**this.formatDate(this.fromDate)
     * Load Products List
     */
    loadProductsList() {

        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );

        this.store.dispatch(search({
            page: queryParams,
            active: this.filterByActive.value,
            categoryId: this.filterCategoryId,
            includeInOrderSplit : this.filterByOrdered.value,
        }))
        this.selection.clear();
    }


    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        // if (this.filterStatus && this.filterStatus.length > 0) {
        //   filter.status = +this.filterStatus;
        // }
        //
        // if (this.filterCondition && this.filterCondition.length > 0) {
        //   filter.condition = +this.filterCondition;
        // }

        filter.query = searchText.trim();
        return filter.query;
    }

    /**
     * Restore state
     *
     * @param queryParams: QueryParamsModel
     * @param id: number
     */
    restoreState(queryParams: QueryParamsModel, id: number) {

        if (!queryParams.filter) {
            return;
        }

        if ('condition' in queryParams.filter) {
            this.filterCondition = queryParams.filter.condition.toString();
        }

        if ('status' in queryParams.filter) {
            this.filterStatus = queryParams.filter.status.toString();
        }

        if (queryParams.filter.model) {
            this.searchInput.nativeElement.value = queryParams.filter.model;
        }
    }

    /** ACTIONS */
    /**
     * Delete configuration
     *
     * @param _item: ProductModel
     */
    deleteConfiguration(_item: ConfigurationModel) {
        const _title = 'Configuration Delete';
        const _description = 'Are you sure to permanently delete this configuration?';
        const _waitDesciption = 'Configuration is deleting...';
        const _deleteMessage = 'Configuration has been deleted';
        const _errorMessage = 'Configuration can’t be deleted';
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
    
            this.http.delete(`https://apiadmin-cpa-dev.wissal-group.com/product-configurations/${_item.id}`)
                .subscribe({
                    next: () => {
                        this.layoutUtilsService.showActionNotification(_errorMessage, MessageType.Delete);
                        this.store.dispatch(ConfigurationDeleted({ configurationId: _item.id }));
                    },
                    error: (err) => {
                        if (err.status === 409) {
                            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Error);
                        } else {
                            this.layoutUtilsService.showActionNotification('An error occurred', MessageType.Error);
                        }
                    }
                });
        });
    }
    
    

    /**
     * Delete configurations
     */
    deleteProducts() {
        const _title = 'Products Delete';
        const _description = 'Are you sure to permanently delete selected products?';
        const _waitDesciption = 'Products are deleting...';
        const _deleteMessage = 'Selected products have been deleted';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            const idsForDeletion: number[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i].id);
                this.store.dispatch(ConfigurationDeleted({configurationId: this.selection.selected[i].id}));
            }
            //todo make this work

            // this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            this.selection.clear();
        });
    }

    showSettings() {
        const dialogRef = this.dialog.open(EkProductsListDialogComponent, {
            width: '600px',
            data: this.columns
        });

        dialogRef.afterClosed().subscribe(rep => {
            if (rep) {
                this.columns = rep;
                localStorage.setItem('setting', JSON.stringify(this.columns));

                this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
            }
        })
    }

    openDialog(row) {

        const dialog = this._dialog.open(EkProductsCharacteristicsDialogComponent, {
            width: '850px',
            // Can be closed only by clicking the close button
            disableClose: true,
            data: row
        });
    }


    /**
     * Check all rows are selected
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.configurationsResult.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection
     */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.configurationsResult.forEach(row => this.selection.select(row));
        }
    }

    /**
     update product.activate
     */

    OnChangeActivateProduct(configuration: ConfigurationModel, value: boolean, event: any) {
        const _configuration = new ConfigurationModel();
        _configuration.id = configuration.id
        _configuration.active = value;
        const updateConfiguration: Update<ConfigurationModel> = {
            id: configuration.id,
            changes: _configuration
        };

        this.store.dispatch(ConfigurationUpdatedActivate({
            configuration: _configuration,
            partialConfiguration: updateConfiguration,
            activate: value,
            updater: this.updater,
            event: event,
        }));

        //ConfigurationUpdatedActivateSuccessfully
        this._actions$
            .pipe(ofType(ConfigurationUpdatedActivateSuccessfully))
            .subscribe((data: any) => {

                let message22 = '';

                if(event.source.checked){
                    message22 = 'Produit activé avec succès.';

                }else{
                    message22 = 'Produit désactivé avec succès.';
                }

                this.layoutUtilsService.showActionNotification(
                    message22,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            });

        //ConfigurationUpdateActivateFailed
        this._actions$
            .pipe(ofType(ConfigurationUpdateActivateFailed))
            .subscribe((data: any) => {
                if (data && (data.error.error.code === 8 || data.error.error.code === 404)) {

                    switch (data.error.error.body) {

                        case "Configuration images are missing." :
                            const message = "vous devez insérer des images de configuration!";
                            this.layoutUtilsService.showActionNotification(
                                message,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration certificate URL is missing or empty." :
                            const message2 = "vous devez insérer le certificat de fabrication de cette configuration!";
                            this.layoutUtilsService.showActionNotification(
                                message2,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration price is missing or invalid." :
                            this.layoutUtilsService.showActionNotification(
                                "Le prix de configuration est manquant ou invalide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration description is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "La description de la configuration est manquante ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Product category or brand is missing." :
                            this.layoutUtilsService.showActionNotification(
                                "La catégorie ou la marque de produit est manquante.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;


                        case "Configuration name is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "Le nom de la configuration est manquant ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Product reference constructor is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "Le ref constructor produit est manquant ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                            case "Activation impossible. This Configuration has no StockEK" :
                                this.layoutUtilsService.showActionNotification(
                                    "Activation impossible. Cette configuration n'a pas de Stock Ek.",
                                    MessageType.Update,
                                    5000,
                                    true,
                                    true
                                );
                                break;


                        default:
                            const message3 = data.error.error.body;
                            this.layoutUtilsService.showActionNotification(
                                message3,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                    }

                }

            });

    }

    onChange(configuration: ConfigurationModel,value) {
        if (value.checked === true) {
            this.OnChangeDisplayProduct(configuration.id, true);
            this.checked = true;
        }
        if (value.checked === false) {
            this.checked = false;
            this.OnChangeDisplayProduct(configuration.id, false);
        }
    }


    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.loadProductsList();
            this.showDatePicker = false;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    formatDate(date: NgbDate) {
        var month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    showPicker() {
        this.showDatePicker = true;
    }

    selectEvent(item) {
        this.filterCategoryId = item.id;
        this.loadProductsList()
    }

    inputCleared() {
        this.filterCategoryId = null
        this.loadProductsList()
    }

    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            this.sort.disabled = true;
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue
            }
            this.columns.find(column => {
                if (column.columnDef == columnName)
                    column.width = event.rectangle.width;

            })
        }
        setTimeout(() => {
            this.sort.disabled = false;
        }, 1000)
    }


    // XLSX

    onClickImages() {
        const fileUploadImages = this.fileUploadImages.nativeElement;
        fileUploadImages.click();
    }

    addfile(event) {
        this.loadingImages = true;

        this.file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async (e) => {
            this.arrayBuffer = fileReader.result;
            const data = new Uint8Array(this.arrayBuffer);
            const arr = new Array();
            for (let i = 0; i !== data.length; ++i) {
                arr[i] = String.fromCharCode(data[i]);
            }
            const bstr = arr.join('');
            const workbook = XLSX.read(bstr, {type: 'binary'});
            const first_sheet_name = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[first_sheet_name];
            const arraylist = XLSX.utils.sheet_to_json(worksheet, {raw: true});
            this.productsNumber = arraylist.length;
            const updatedArrayList = await this.getStuff(arraylist);
            this.filelist = [];
            const saved = XLSX.utils.json_to_sheet(updatedArrayList);
            const newbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(newbook, saved);

            XLSX.writeFile(newbook, 'uploaded' + Date.now() + '.xlsx');
            this.loadingImages = false;
        };
    }


    async uploadUrls(urls: string[], productName: string) {
        let imagesBlock = '';
        let i = 1;
        for (const url of urls) {
            if (url.length > 10) {
                const data = await this.imageService.fetchAndUploadImage(url, productName + i).toPromise();
                imagesBlock += data['imageUrl'] + ',';
                i++;
            }
        }
        return imagesBlock;
    }

    async getStuff(arraylist) {
        let updatedArrayList = [];
        for (const product of arraylist) {
            if (product['Liens Images']) {
                const imageUrl: string[] = product['Liens Images'].split(',');
                if (imageUrl!== []) {
                    product['Liens Images'] = await this.uploadUrls(imageUrl, product['Ref_constructeur']);
                }
            }
            updatedArrayList.push(product);
            this.progress$.next(Math.round(updatedArrayList.length * 100 / this.productsNumber));
        }
        return updatedArrayList;
    }

    onResizeTable(event) {
        localStorage.setItem('product-matTableMaxHeight', event.rectangle.height)
        this.matTableMaxHeight = event.rectangle.height
    }

    getTableMaxHeight() {
        if (localStorage.getItem('product-matTableMaxHeight'))
            this.matTableMaxHeight = Number(localStorage.getItem('product-matTableMaxHeight'))
    }

    getSavedListSetting() {
        if (JSON.parse(localStorage.getItem('setting')) && JSON.parse(localStorage.getItem('setting')).length > 0) {
            let savedConfig: [{
                columnDef: string,
                width: number,
                header: string,
                type?: any,
                cell: any,
                selected: boolean
            }] = JSON.parse(localStorage.getItem('setting'));
            if (!savedConfig) {
                savedConfig = JSON.parse(localStorage.getItem('setting'));
            }
            let newList = [];
            savedConfig.forEach(elem => {
                const selectedelem = this.columns.filter(res => res.columnDef == elem.columnDef);
                let newElement = selectedelem[0];
                newElement.selected = elem.selected;
                newElement.width = elem.width
                newList.push(newElement)
            })
            this.columns = newList;
            this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
        }
    }

    filterByActivate() {
        this.filterByActive.valueChanges
            .subscribe(
                value => {
                    this.loadProductsList();
                }
            )
    }

    filterByOrderedFunc() {
        this.filterByOrdered.valueChanges
            .subscribe(
                value => {
                    this.loadProductsList();
                }
            )
    }

    OnChangeDisplayProduct(configurationId:number,display: boolean) {

        this.store.dispatch(displayConfiguration({
            configurationId: configurationId,
            display: display,

        }));

        //ConfigurationUpdatedActivateSuccessfully
        this._actions$
            .pipe(ofType(displayConfigurationSuccessfully))
            .subscribe((data: any) => {

                let message22 = '';

                if(display==true){
                    message22 = 'affiché sur guichet avec succès.';

                }else{
                    message22 = 'Produit cache sur guichet avec succès.';
                }

                this.layoutUtilsService.showActionNotification(
                    message22,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            });

        //ConfigurationUpdateActivateFailed
        this._actions$
            .pipe(ofType(displayConfigurationFailed))
            .subscribe((data: any) => {
                this.layoutUtilsService.showActionNotification(
                    "veuillez activer le produit ",
                    MessageType.Update,
                    5000,
                    true,
                    true
                );

            });

    }
}
