import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AgencyBankDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/agencyBank.datasource";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {SelectionModel} from "@angular/cdk/collections";
import {BehaviorSubject, merge, Observable, Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {AgencyBankModel} from "../../../../../../core/ek-e-commerce/ek-models/agency-bank-model";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../core/_base/crud";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {AgencyBankService} from "../../../../../../core/ek-e-commerce/ek-services/agency-bank.service";
import {distinctUntilChanged, skip, tap} from "rxjs/operators";
import {getlastAction} from "../../../../../../core/ek-e-commerce/ek-selectors/agency-bank.selector";
import * as AgencyBankActions from "../../../../../../core/ek-e-commerce/ek-actions/agency-bank.actions";
import {BankerListPopupComponent} from "../banker-list-popup/banker-list-popup.component";
import {FormControl} from "@angular/forms";
import {RegionService} from "../../../../../../core/ek-e-commerce/ek-services/region.service";
import {WilayaService} from "../../../../../../core/e-commerce/_services/wilaya.service";

@Component({
    selector: 'kt-agency-bank',
    templateUrl: './agency-bank.component.html',
    styleUrls: ['./agency-bank.component.scss']
})
export class AgencyBankComponent implements OnInit, OnDestroy {

    dataSource: AgencyBankDatasource;
    displayedColumns = ['select', 'id', 'firstNameManager', 'lastNameManager', 'createdAt', 'code', 'nameAgency', 'commune', 'wilaya', 'phone', 'region', 'edit'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
   // @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    // Selection
    selection = new SelectionModel<AgencyBankModel>(true, []);
    agencyBankResult: AgencyBankModel[] = [];
    // Subscriptions
    private subscriptions: Subscription[] = [];
    lastAction$: Observable<string>;
    //Permissions
    private PERMISSIONS = ['ALL_HOME'];
    canEdit = false;

    agencyBank: AgencyBankModel;
    select: number;

    currentRole: string = '';
    selectedAgencyBanksCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    selectedAgencysBankId: number;

    filterByRegion = new FormControl();
    filterByWilaya = new FormControl();
    regionList = [];

    
    wilayas = []
    /**
     * Component constructor
     *
     * @param dialog
     * @param snackBar
     * @param layoutUtilsService
     * @param translate
     * @param regionService
     * @param store
     * @param store
     * @param cdr
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param store
     * @param ngxPermissionService
     * @param agencyBankService
     * @param router
     * @param ngxPermissionService
     * @param agencyBankService
     * @param activatedRoute
     * @param ngxPermissionService
     * @param agencyBankService
     */
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private regionService: RegionService,
        private store: Store<AppState>,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private ngxPermissionService: NgxPermissionsService,
        private agencyBankService: AgencyBankService,
        private wilayaService: WilayaService
    ) {

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

    }

    ngOnInit() {

        this.wilayas = this.wilayaService.getWilayasItems();

        this.getRegionsList();

        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        this.paginator._changePageSize(100);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.loadAgencyBankList();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Init DataSource
        this.dataSource = new AgencyBankDatasource(this.store);

        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.agencyBankResult = res;

        });
        this.subscriptions.push(entitiesSubscription);

        // First load
        this.loadAgencyBankList();
        this.lastAction$ = this.store.select(getlastAction);
        this.checkPermissionToUpdate();
    }

    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });

    }

    deleteAgencyBanks(id: number) {
        const _title: string = this.translate.instant('ECOMMERCE.AGENCYBANK.DELETE_AGENCYBANK_SIMPLE.TITLE');
        const _description: string = this.translate.instant('ECOMMERCE.AGENCYBANK.DELETE_AGENCYBANK_SIMPLE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ECOMMERCE.AGENCYBANK.DELETE_AGENCYBANK_SIMPLE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ECOMMERCE.AGENCYBANK.DELETE_AGENCYBANK_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(AgencyBankActions.OneAgencyBankDeleted({id: id}));
            const deleteSub = this.lastAction$.subscribe(result => {
                if (result == 'delete Success') {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    return;
                } else
                    this.layoutUtilsService.showActionNotification("Deleting Agency bank failed !", MessageType.Delete);

            });
            this.subscriptions.push(deleteSub);
        });
    }


    onAgencyBankToggled(agencyBank: AgencyBankModel) {
        this.selection.clear();
        this.selection.toggle(agencyBank);
        this.selectedAgencysBankId=agencyBank.id;
      }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    loadAgencyBankList() {
        this.selection.clear();

        const queryParams = new QueryParamsModel(
            null,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        // Call request from server
        this.store.dispatch(AgencyBankActions.AgencyBankPageRequested({
            page: queryParams,
            regionId: this.filterByRegion.value,
            wilaya: this.filterByWilaya.value
        }));

        this.selection.clear();
    }

    editagencyBank(id) {
        this.router.navigate(['../agencyBank/edit', id], {relativeTo: this.activatedRoute});

    }

    openBankerList() {

        this.dialog.open(BankerListPopupComponent, {
            width: '950px',
            height: '600px',
            panelClass: 'analysts-popup',
            data: this.selectedAgencysBankId
        }).afterClosed().subscribe(()=> {
            this.closeAtt()
        })
    }
    closeAtt() {
        this.selection.clear();
        this.selectedAgencysBankId = null as any;
        this.cdr.detectChanges();
    }

    getRegionsList() {
        this.regionService.getRegionList().subscribe({
            next: (res) => {
                this.regionList = res;
            }
        });
    }

    getRegionName(id: number) {
        if (id > 0) {
            const region = this.regionList.find(item => item[0] === id);
            return region[1];
        }
        return null;
    }
}
