import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import * as ClientActions from "../ek-actions/client.actions";
import {ClientsService} from "../ek-services/clients.service";


@Injectable()
export class ClientEffects {
    constructor(private actions$: Actions,
                private clientsService: ClientsService,
    ) {
    }

    ClientPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.clientsPageRequested),
            switchMap((action) =>
                this.clientsService.getAllClients(action.page.pageNumber, action.page.pageSize, action.page.sortOrder, action.page.sortField, action.page.filter, action.clientState, action.firstDate, action.lastDate)
                    .pipe(
                        map(res => ClientActions.clientsPageLoadedSuccessfully(
                            {
                                clients: res.body.content,
                                page: res.body.totalPages,
                                totalCount: res.body.totalElements
                            })),
                        catchError(error => of(ClientActions.clientsPageLoadFailed({error})))
                    ))));

    CustomerCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.CustomerCreated),
            switchMap((action) =>
                this.clientsService.save(action.customer)
                    .pipe(
                        map(customer => ClientActions.CustomerCreatedSuccessfully({customer})),
                        catchError(error => of(ClientActions.CustomerCreationFailed({error})))
                    ))));

    CustomerUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.CustomerUpdated),
            switchMap((action) =>
                this.clientsService.update(action.customer)
                    .pipe(
                        map(customer => ClientActions.CustomerUpdatedSuccessfully({
                            customer,
                            partialCustomer: action.partialCustomer
                        })),
                        catchError(error => of(ClientActions.CustomerUpdateFailed({error})))
                    ))));

    CustomerDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.OneCustomerDeleted),
            switchMap((action) =>
                this.clientsService.delete(action.id)
                    .pipe(
                        map(() => ClientActions.OneCustomerDeletedSuccessfully({id: action.id})),
                        catchError(error => of(ClientActions.OneCustomerDeleteFailed({error})))
                    ))));
    ClientOrdersPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.clientsOrdersPageRequested),
            switchMap((action) =>
                this.clientsService.getClientsWithSameOrderedProduct(action.page.pageNumber, action.page.pageSize, action.page.criteria, action.page.sortOrder, action.page.filter, action.productId)
                    .pipe(
                        map(res => ClientActions.clientsPageLoadedSuccessfully(
                            {
                                clients: res.content,
                                page: res.totalPages,
                                totalCount: res.totalElements
                            })),
                        catchError(error => of(ClientActions.clientsPageLoadFailed({error})))
                    ))));

}
