import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {ListingModel} from "../ek-models/listing-model";
import {map} from "rxjs/operators";
import {FolderModel, FolderStates} from "../ek-models/folder.model";
import {ShippingAddressFolder} from "../../../../../../ek-guichet/src/app/Models/shipping-address-folder";
import {Statistics} from "../ek-models/count-statistics.model";


@Injectable()
export class FoldersService extends AbstractService<ListingModel> {

    url: string;
    url2: string;
    shippingAdrUrl: string;

    selectedFolderSubject$ = new BehaviorSubject<FolderModel>(null);
    isCheckSbj$ = new BehaviorSubject<boolean>(false);
    readonly selectedFolder$ = this.selectedFolderSubject$.asObservable();

    folderSbj$ = new BehaviorSubject<FolderModel>(null);
    FolderObs$ = this.folderSbj$.asObservable();

    folderStateSubj$ = new BehaviorSubject<FolderStates>(null);

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}orderSplit`;
        this.url2 = `${ADMIN_CONFIG.apiEndpoint}folder`;
        this.shippingAdrUrl = `${ADMIN_CONFIG.apiEndpoint}shippingAddressFolder`;
    }

    public getAllFoldersListing(pageNumber: number = 0, pageSize: number = 50, sortBy: string, direction : string, query: string = "", folderState: string, orderState:string,  agencyBankId: string, firstDate: string, lastDate: string, archived: boolean, idPos: number,creatorType:string): Observable<Response<Page<ListingModel>>> {

        let params = new HttpParams();

        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sortBy', sortBy);
        params = params.append('direction', direction);
        params = query ? params.append('query', query) : params;
        params = agencyBankId ? params.append('agencyBankId', agencyBankId) : params;
        params = folderState ? params.append('folderState', folderState) : params;
        params = orderState ? params.append('state', orderState) : params;
        params = firstDate ? params.append('firstDate', firstDate) : params;
        params = lastDate ? params.append('lastDate', lastDate) : params;
        params = idPos ? params.append('idPos', idPos.toString()) : params;
        params = creatorType ? params.append('creatorType', creatorType.toString()) : params;
        if (archived === null || archived === undefined) {
            params = params.append('archived', 'false'); // Display unarchived folders by default
        } else {
            params = params.append('archived', archived.toString());
        }
        const options = {params};
        const url = `${this.url}/listing`;
        return this.http.get<Response<Page<ListingModel>>>(url, options);
    }


    assignCreditAnalyst(folderIds: number[], wsUserId: number) {
        let params = new HttpParams
        params = params.append("folderIds", folderIds.toString())
        const url = `${this.url2}/creditAnalyst/${wsUserId}`
        return this.http.patch<Response<any>>(url, params)
    }

    public getShippingAddressFolder(folderId: number) {
        const url = `${this.shippingAdrUrl}/folder/${folderId}`;
        return this.http.get<Response<any>>(url);
    }

    public getFolderById(idFolder: number): Observable<FolderModel> {
        const url = `${this.url2}/${idFolder}`;
        return this.http.get<Response<FolderModel>>(url).pipe(map(({body}) => body));
    }

    public isChecked(folderId: number) {
        const url = `${this.url2}/hasChecked/${folderId}`;
        return this.http.get<Response<any>>(url)
    }

    public changeState(idFolder: number, event: string) {
        const url = `${this.url2}/changeState/${idFolder}/${event}`;
        return this.http.get<Response<FolderStates>>(url).pipe(map(({body}) => body));
    }

    public sendfileShippingType(folderShipping: ShippingAddressFolder): Observable<any> {
        const url = `${this.shippingAdrUrl}`
        return this.http.post(url, folderShipping);
    }

    public ordersStatistics() {
        const url = `${this.url2}/count/states`;
        return this.http.get<Statistics>(url);
    }
    public multiStateChange(folderIds : number[], event : string, comment?:string) {
        const url = `${this.url2}/bulk/valid-states/${event}`
        let params = new HttpParams;
        params = comment ? params.append("comment", comment) : params;
        return this.http.post(url, folderIds ,{
            responseType: 'text',
            params:params
        });
    }

    public FoldersStatistics() {
        const url = `${this.url2}/count/states`;
        return this.http.get<Statistics>(url);
    }

    public GlobalFolderStatistics() {
        const url = `${this.url2}/bank/count/states`;
        return this.http.get<Statistics>(url);
    }

    
    affectAgency(folderIds: number[], bankAgencyId: number) {
        const url = `${this.url2}/agencyBank/${bankAgencyId}`
        let params = new HttpParams;
        params = params.append('folderIds', folderIds.toString())
        return this.http.patch<Response<any>>(url, params)
    }

    cancelFolder(folderId: number): Observable<any>{
        const url = `${this.url2}/cancelFolder/${folderId}`;
        // @ts-ignore
        return this.http.patch<any>(url,null,{responseType: 'text'});
    }

    duplicateFolder(folderId: number): Observable<any> {
        return this.http.post<any>(`${this.url2}/${folderId}/duplicate`, {});
    }


}


