import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {OrderSplit} from "../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {FolderModel, FolderStates} from "../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {FoldersService} from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {OrderSplitService} from "../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {FormControl} from "@angular/forms";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {MatDialog} from "@angular/material/dialog";
import {AddCommentComponent} from "../folder-steps/folder-step-eight/add-comment/add-comment.component";
import {BehaviorSubject, Observable} from "rxjs";
import {DocumentService} from "../../../../../../core/services/document.service";
import {ocrInfo} from "../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {FolderVerificationService} from "../../../../../../core/services/folder-verification.service";
import {DocumentModel} from "../../../../../../core/ek-e-commerce/ek-models/document.model";
import {MenuConfigService} from '../../../../../../core/_base/layout';
import {
    ConfirmDuplicateDialogComponent
} from "../folders-pop-up/components/confirm-duplicate-dialog/confirm-duplicate-dialog.component";


@Component({
    selector: 'kt-folder-process',
    templateUrl: './folder-process.component.html',
    styleUrls: ['./folder-process.component.scss']
})
export class FolderProcessComponent implements OnInit, OnDestroy {

    @Input() orderSplit: OrderSplit;
    @Input() folder: FolderModel;
    @Input() isNewOrder: boolean = false;

    folderState: FolderStates;
    remarkStatus = new FormControl();
    idFolder = 0;
    idOrder = 0;
    OrdersTitle: string;
    step: number;
    public folderStateObs$: Observable<FolderStates>;

    folderStateLoadObs$ = new BehaviorSubject<boolean>(false);
    readonly loadingState$ = this.folderStateLoadObs$.asObservable();

    currentRole: string = '';

    ocrInfos: ocrInfo = null;

    bankDocHasCheked = false;

    allOneByOneIsChecked = false;

    documentStates: string [] = [];

    constructor(private route: ActivatedRoute,
                private folderService: FoldersService,
                private orderSplitService: OrderSplitService,
                private router: Router,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
                private documentService: DocumentService,
                public  folderVerificationService: FolderVerificationService,
                private menuConfigService: MenuConfigService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe((params: any) => {
            this.step = params['step'] ? Number(params['step']) : 1;
        });

        // Empty Order - adding new order
        this.route.paramMap.subscribe(params => {
            this.idFolder = Number(params.get('idFolder'));
            this.idOrder = Number(params.get('idOrder'));
        });

        // check bank docs ...
        this.documentService.checkBankDocuments(this.idFolder).subscribe({
            next: (res) => {
                if (String(res.body).includes('All the documents are uploaded')) {
                    this.documentService.isBankDocCheckedSubject.next(true);
                }
            },
            error: () => {
                this.documentService.isBankDocCheckedSubject.next(false);
            }
        });

        this.documentService.allOneByOneIsCheckedSubject.subscribe(data => {
            this.allOneByOneIsChecked = data;
        });

        this.documentService.isBankDocCheckedSubject.subscribe(data => {
            this.bankDocHasCheked = data;
        });

        this.documentService.ocrSubject.subscribe(data => {
            this.ocrInfos = data;
        });

        this.route.paramMap.subscribe((res: ParamMap) => {
            this.OrdersTitle = this.route.snapshot.data['title'];
        });

        this.folderService.selectedFolderSubject$.subscribe((res) => {
            if (res) {
                this.folder = res;
                if (!this.isNewOrder) this.folderService.folderStateSubj$.next(res.folderState);
                this.folderStateObs$ = this.folderService.folderStateSubj$.asObservable();
                this.folderState = res.folderState;
            }
        });

        this.folderService.folderStateSubj$.subscribe(state => this.folderState = state);

        //list of documents states which have type different from GUARANTEES , BANK , CREDIT & BANK_AUTHOR
        this.folderVerificationService.documents$.subscribe({
            next: (documents) => {
                if (documents) {
                    this.documentStates = documents.filter((document: DocumentModel) =>
                        document.documentsType !== 'GUARANTEES'
                        && document.documentsType !== 'BANK'
                        && document.documentsType !== 'INVOICE'
                        && document.documentsType !== 'CREDIT'
                        && document.documentsType !== 'BANK_AUTHOR'
                        && document.documentsGender !== 'OTHER').map(item => item.documentsState);
                }
            }
        })

    }


    isValidateButtonDisabled() {
        //if documents are not uploaded and accepted , role credit can not validate folder ...
        return !!(this.documentStates.length === 0
            || this.documentStates.includes('PENDING')
            || this.documentStates.includes('REFUSED'));
    }

    isButtonHidden(status: string) {

        if (this.folderState) {
            switch (status) {
                case 'RETURN':
                    //
                    return !(this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.folderState === 'EK_WAITING_FOR_CLIENT'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'BANK_NOTIFICATION'
                    ) || this.currentRole==='ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole ==='ROLE_GUEST_ADMIN';

                case 'REFUSE':
                    return !(this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PENDING'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'BANK_NOTIFICATION'
                        || this.folderState === 'EK_GUARTENTEES_PENDING_SIGNATURE'
                    ) || this.currentRole==='ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole ==='ROLE_GUEST_ADMIN';
                case 'CANCEL':
                    return this.folderState === 'EK_ADMIN_FOLDER_CANCELED'
                case 'VALIDATE':
                    //if document non verified , pos can not validate folder
                    if (((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')
                            && this.folderState === 'EK_FOLDER_CREATED'
                            && (!this.ocrInfos || !this.bankDocHasCheked || !this.allOneByOneIsChecked))
                    ||  this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole === 'ROLE_GUEST_ADMIN') {
                        return true;
                    }
                    return !(this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_RESERVE'
                        || this.folderState === 'EK_BANK_RESERVE'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_RESERVE'
                        || this.folderState === 'EK_FOLDER_CREATED'
                        || this.folderState === 'EK_GUARTENTEES_PENDING_SIGNATURE'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PENDING'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_DELIVERED'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PICKEDUP'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.folderState === 'BANK_NOTIFICATION'
                        || this.folderState === 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK');
            }

        }
    }


    //first state : EK_FOLDER_IN_PROGRESS
    changeOrderStatus(status: string) {

        this.folderStateLoadObs$.next(true);

        switch (status) {

            //refuse folder
            case 'REFUSE' :

                this.openDialog();

                break;

            //return folder
            case 'RETURN' :
                switch (this.folder.folderState) {

                    case "EK_FOLDER_IN_PROGRESS":
                        //supposed res : EK_FOLDER_RESERVE
                        // this.folderService.changeState(this.folder.id, 'EK_RETURN_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_RETURN_FOLDER');
                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : EK_BANK_RESERVE
                        // this.folderService.changeState(this.folder.id, 'EK_BANK_RETURN_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_BANK_RETURN_FOLDER');
                        break;

                    case 'EK_GUARTENTEES_PENDING_SIGNATURE':
                        //supposed res : BANK_NOTIFICATION
                       // this.changeState('EK_GUARTENTEES_PENDING_SIGNATURE_TO_BANK_NOTIFICATION');
                        this.openDialog('EK_GUARTENTEES_PENDING_SIGNATURE_TO_BANK_NOTIFICATION');
                        break;

                    // case 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK':
                    //     //supposed res : EK_GUARTENTEES_PENDING_SIGNATURE
                    //     this.changeState('EK_REVERSE_EK_GUARTENTEES_SIGNED_IN_PROGRESS_TO_GUARTENTEES_PENDING_SIGNATURE');
                    //     break;

                    case 'FINANCIAL_FOLDER':
                        //supposed res : GUARTENTEES_SIGNED_IN_PROGRESS_BANK
                       // this.changeState('FINANCIAL_FOLDER_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS');
                        this.openDialog('FINANCIAL_FOLDER_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS');
                        break;

                    case 'BANK_NOTIFICATION':
                        //supposed res : EK_BANK_RESERVE
                       this.changeState('BANK_NOTIFICATION_TO_EK_BANK_RESERVE');
                       break;

                }
                break;

            //validate folder
            case 'VALIDATE' :
                switch (this.folder.folderState) {

                    case "EK_FOLDER_CREATED":
                        //supposed res : EK_FOLDER_IN_PROGRESS
                        this.changeState('EK_IN_PROGRESS_FOLDER');
                        break;

                    case "EK_FOLDER_IN_PROGRESS":
                        //supposed res : EK_BANK_IN_PROGRESS
                        this.changeState('EK_VALIDATE_FOLDER');
                        // this.folderService.changeState(this.folder.id, 'EK_VALIDATE_FOLDER').subscribe((e) => this.updateOrderState(e));
                        break;

                    case 'EK_BANK_RESERVE':

                        switch (this.folder.folderPreviousState) {
                            case 'EK_BANK_IN_PROGRESS' :
                                // supposed res : EK_BANK_IN_PROGRESS
                                this.changeState('EK_REVERSE_BANK_RESERVE_TO_BANK_IN_PROGRESS');
                                break;
                            case 'BANK_NOTIFICATION' :
                                // supposed res : BANK_NOTIFICATION
                                this.changeState('EK_BANK_RESERVE_TO_BANK_NOTIFICATION');
                                break;
                        }

                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        //supposed res : BANK_NOTIFICATION
                        this.changeState('EK_BANK_IN_PROGRESS_TO_BANK_NOTIFICATION');
                        break;
                    case 'BANK_NOTIFICATION':
                        //supposed res : EK_CONTROL_BEFORE_GUARANTEES_PRINTING
                        this.changeState('BANK_NOTIFICATION_TO_EK_CONTROL_BEFORE_GUARANTEES_PRINTING');
                        break;
                    case 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING':
                        //supposed res : EK_GUARTENTEES_PENDING_SIGNATURE
                        this.changeState('EK_CONTROL_BEFORE_GUARANTEES_PRINTING_TO_EK_GUARTENTEES_PENDING_SIGNATURE');
                        break;
                    case 'EK_GUARTENTEES_PENDING_SIGNATURE':
                        //supposed res : GUARTENTEES_SIGNED_IN_PROGRESS_BANK
                        this.changeState('EK_WAITING_FOR_GUARANTIES_VALIDATION');
                        break;
                    case 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK':
                        //supposed res : FINANCIAL_FOLDER
                        this.changeState('EK_GUARTENTEES_SIGNED_IN_PROGRESS_TO_FINANCIAL_FOLDER');
                        // this.folderService.changeState(this.folder.id, 'EK_WAITING_FOR_PHYSICAL_FOLDER').subscribe((e) => this.updateOrderState(e));
                        break;

                    case 'EK_FOLDER_RESERVE':
                        //supposed res : EK_FOLDER_IN_PROGRESS
                        this.changeState('EK_REVERSE_RESERVE_TO_PROGRESS');
                        break;



                }
                break;
        }
    }


    changeState(state: string) {
        this.folderService.changeState(this.folder.id, state).subscribe({
            next: (res) => {
                this.updateOrderState(res);
                this.folderStateLoadObs$.next(false);
                // reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();
            },
            error: (error) => {

                if (error.error.code === 401) {
                    this.folderStateLoadObs$.next(false);
                    const message = `vous pouvez pas faire cette opération !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }

                if (error.status === 403) {
                    this.folderStateLoadObs$.next(false);
                    const message = `vous pouvez pas changer le statu !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            },
        });
    }

    updateOrderState(folderState: FolderStates) {
        //change order split state
        switch (folderState) {
            case "EK_BANK_PHYSICAL_FOLDER_ACCEPTED":
                //prepare order
                this.changeStateOrder('EK_PREPARE_ORDER');
                break;
            case "EK_BANK_REFUSED" || "EK_FOLDER_REFUSED" || "EK_PHYSICAL_FOLDER_REFUSED":
                //cancel order split
                this.cancelOrderSplit();
                break;
        }

        this.refresh();
    }

    refresh() {
        this.folderService.getFolderById(this.folder.id).subscribe(res => {
            if (res) {
                this.folderService.folderStateSubj$.next(res.folderState)
                this.folderState = res.folderState;
                this.folderService.selectedFolderSubject$.next(res);
                this.folderStateLoadObs$.next(false);
                this.router.navigateByUrl('ek-ecommerce/folder/edit/' + this.idOrder + '/' + this.idFolder + '?step=' + this.step);
            }
        });

        // reload aside left folders list
        this.getFoldersStatisticsForMenuConfigs();
    }

    changeStateOrder(orderState) {
        this.orderSplitService.changeStateOrderSplit(this.idOrder, orderState).subscribe(res => {
            if (res) {
                const message = `le statut de la commande a été bien modifié!`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        });
    }


    cancelOrderSplit() {

        let date = new Date();

        if (this.remarkStatus.value) {
            let cancelObj = {
                cancellingDate: date.toISOString(),
                cancellingReason: this.remarkStatus.value
            }

            //return status : EK_CLIENT_ORDER_CANCELED
            this.orderSplitService.cancelOrderSplit(this.idOrder, cancelObj).subscribe(res => {
                if (res) {
                    const message = `le statut de la commande a été bien annulée!`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            });
        }

    }


    getFoldersStatisticsForMenuConfigs() {

        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.menuConfigService.statisticsSubject$.next(null as any);
                this.menuConfigService.statisticsSubject$.next(res.countFolderState);
            }
        });

        if (this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_BANKER_EK'
        ) {
            this.folderService.GlobalFolderStatistics().subscribe({
                next: (res) => {
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
                }
            });
        }


    }


    openDialog(nextFolderState?: string) {

        let title = 'Motif d\'annulation';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: true,
                    idFolder: this.idFolder,
                    idOrder: this.idOrder,
                    title,
                    folderState: this.folder.folderState,
                    nextFolderState
                }
            }
        );

        dialogRef.afterClosed().subscribe((content) => {
            this.folderStateLoadObs$.next(false);
            if (nextFolderState && content) {
                this.updateOrderState(content);
            } else {
                this.refresh();
            }
        });
    }

    ngOnDestroy() {
        this.folderService.folderStateSubj$.next(null);
    }

    cancelFolder(): void {

        this.folderStateLoadObs$.next(true);
        const title = 'Motif d\'annulation';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: false,
                    idFolder: this.idFolder,
                    idOrder: this.idOrder,
                    title,
                    folderState: this.folder.folderState,
                    nextFolderState: null,
                    isCancelFolder: true
                }
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            this.folderStateLoadObs$.next(false);
            this.refresh();
        });

    }

    duplicateFolder(): void {
        this.folderService.duplicateFolder(this.folder.id).subscribe(
            (newFolderId) => {
                // Use the newly created folder ID to fetch the corresponding Order Split ID
                this.orderSplitService.getOrderSplitIdByFolderId(newFolderId).subscribe(
                    (newOrderSplitId) => {
                        // Redirect to the new URL with the parameters
                        this.router.navigateByUrl(
                            `ek-ecommerce/folder/edit/${newOrderSplitId}/${newFolderId}?step=${this.step}`
                        );
                    },
                    (error) => {
                        // Handle error when fetching the newOrderSplitId
                        this.handleError(error, 'Failed to fetch Order Split ID');
                    }
                );
            },
            (error) => {
                // Handle error when duplicating the folder
                this.handleError(error, 'Failed to duplicate folder');
            }
        );
    }

    // Error handling helper function
    private handleError(error: any, defaultMessage: string): void {
        let errorMessage = defaultMessage;

        if (error.error && error.error.message) {
            errorMessage = error.error.message;
        } else if (error.status === 404) {
            errorMessage = 'Resource not found';
        } else if (error.status === 401) {
            errorMessage = 'Unauthorized access';
        } else if (error.status === 500) {
            errorMessage = 'Internal server error';
        }

        alert('Une erreur s\'est produite lors de la duplication du dossier.');
    }

    openConfirmationDialog(): void {
        const dialogRef = this.dialog.open(ConfirmDuplicateDialogComponent, {
            width: '400px',
            data: { message: 'Voulez-vous vraiment dupliquer ce dossier ?' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.duplicateFolder();
            }
        });
    }
}
